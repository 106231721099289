import { emptySplitApi as api } from './emptyApi';
export const addTagTypes = [
  'AUTH',
  'ADMIN UI',
  'LOGIN PAGE',
  'USER UI',
  'USERS',
  'CREATE CAMPAIGN TARGETING PAGE',
  'MANAGEMENT CLUBS PAGE',
  'USER LIST PAGE',
  'CREATE USER PAGE',
  'DASHBOARD PAGE',
  'STATISTICS',
  'EDIT USER ADD ADDRESS PAGE',
  'SOCIAL NETWORKS',
  'CAMPAIGNS',
  'CAMPAIGNS LIST PAGE',
  'CREATE CAMPAIGN CONTENT PAGE',
  'CREATE CAMPAIGN FINISH PAGE',
  'MANAGE CAMPAIGNS PAGE',
  'CAMPAIGN DETAIL PAGE',
  'CHAT PAGE',
  'ORDERS',
  'MANAGE CAMPAIGN CONTENT EXPANDED PAGE',
  'CAMPAIGN REQUEST PAGE',
  'MY ORDERS PAGE',
  'MEDIAS',
  'CAMPAIGN REPORTING PAGE',
  'EDIT USER EDIT ADDRESS PAGE',
  'EDIT USER EDIT RATINGS PAGE',
  'EDIT USER GENERAL PAGE',
  'EDIT USER NOTIFICATIONS PAGE',
  'EDIT USER RATINGS OVERVIEW PAGE',
  'EDIT USER SOCIAL NETWORK LINKS PAGE',
  'EDIT USER SOCIAL NETWORK STATISITCS',
  'USER ACCOUNT PAGE',
  'EDIT USER CHANGE PASSWORD PAGE',
  'EDIT USER ADD RATINGS PAGE',
  'BRANDS',
  'BRAND LIST PAGE',
  'CREATE BRAND PAGE',
  'EDIT BRAND NOTIFICATIONS PAGE',
  'EDIT BRAND PAGE',
  'EDIT BRAND SOCIAL NETWORK LINKS PAGE',
  'EDIT BRAND CHANGE PASSWORD PAGE',
  'CLUBS',
  'CREATE CLUB PAGE',
  'EDIT CLUB PAGE',
  'CHAT',
  'NOTIFICATIONS',
  'MY ORDERS ADD IG MEDIA PAGE',
  'MY ORDERS ADD YOUTUBE VIDEO PAGE',
  'MY ORDERS ADD YOUTUBE SHORTS PAGE',
  'UPLOAD',
  'PERMISSION',
  'SECONDARY ADMIN',
  'NEWS',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation<LoginApiResponse, LoginApiArg>({
        query: (queryArg) => ({ url: `/manage/login`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['AUTH', 'ADMIN UI', 'LOGIN PAGE', 'USER UI'],
      }),
      logout: build.mutation<LogoutApiResponse, LogoutApiArg>({
        query: () => ({ url: `/manage/logout`, method: 'POST' }),
        invalidatesTags: ['AUTH', 'ADMIN UI', 'USER UI'],
      }),
      postManageGoogleLogin: build.mutation<
        PostManageGoogleLoginApiResponse,
        PostManageGoogleLoginApiArg
      >({
        query: (queryArg) => ({ url: `/manage/google-login`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['AUTH'],
      }),
      postManageFacebookLogin: build.mutation<
        PostManageFacebookLoginApiResponse,
        PostManageFacebookLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/facebook-login`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['AUTH'],
      }),
      postManageForgotPassword: build.mutation<
        PostManageForgotPasswordApiResponse,
        PostManageForgotPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/forgot-password`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['USERS', 'LOGIN PAGE', 'ADMIN UI', 'USER UI'],
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: (queryArg) => ({
          url: `/manage/users`,
          params: {
            campaignId: queryArg.campaignId,
            limit: queryArg.limit,
            offset: queryArg.offset,
            search: queryArg.search,
            filterStatus: queryArg.filterStatus,
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            excludeClub: queryArg.excludeClub,
          },
        }),
        providesTags: [
          'USERS',
          'CREATE CAMPAIGN TARGETING PAGE',
          'MANAGEMENT CLUBS PAGE',
          'USER LIST PAGE',
          'ADMIN UI',
        ],
      }),
      postUsers: build.mutation<PostUsersApiResponse, PostUsersApiArg>({
        query: (queryArg) => ({ url: `/manage/users`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['USERS', 'ADMIN UI', 'CREATE USER PAGE'],
      }),
      patchManageUsers: build.mutation<PatchManageUsersApiResponse, PatchManageUsersApiArg>({
        query: (queryArg) => ({ url: `/manage/users`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['USERS', 'ADMIN UI', 'USER LIST PAGE'],
      }),
      getManageUsersToValidate: build.query<
        GetManageUsersToValidateApiResponse,
        GetManageUsersToValidateApiArg
      >({
        query: () => ({ url: `/manage/users/to-validate` }),
        providesTags: ['USERS', 'DASHBOARD PAGE', 'ADMIN UI'],
      }),
      getGeneralStatistics: build.query<
        GetGeneralStatisticsApiResponse,
        GetGeneralStatisticsApiArg
      >({
        query: () => ({ url: `/manage/statistics/general` }),
        providesTags: ['STATISTICS', 'ADMIN UI'],
      }),
      getUserStatistics: build.query<GetUserStatisticsApiResponse, GetUserStatisticsApiArg>({
        query: () => ({ url: `/manage/statistics/general-information/dashboard` }),
        providesTags: ['STATISTICS', 'DASHBOARD PAGE', 'ADMIN UI'],
      }),
      getManageStatisticsPlatformUsersDashboard: build.query<
        GetManageStatisticsPlatformUsersDashboardApiResponse,
        GetManageStatisticsPlatformUsersDashboardApiArg
      >({
        query: () => ({ url: `/manage/statistics/platform-users/dashboard` }),
        providesTags: ['STATISTICS', 'DASHBOARD PAGE', 'USERS', 'ADMIN UI'],
      }),
      getSocialNetworksStatistics: build.query<
        GetSocialNetworksStatisticsApiResponse,
        GetSocialNetworksStatisticsApiArg
      >({
        query: () => ({ url: `/manage/statistics/social-networks/dashboard` }),
        providesTags: [
          'STATISTICS',
          'DASHBOARD PAGE',
          'EDIT USER ADD ADDRESS PAGE',
          'SOCIAL NETWORKS',
          'ADMIN UI',
        ],
      }),
      getCampaigns: build.query<GetCampaignsApiResponse, GetCampaignsApiArg>({
        query: () => ({ url: `/manage/campaigns` }),
        providesTags: ['CAMPAIGNS', 'CAMPAIGNS LIST PAGE', 'DASHBOARD PAGE', 'ADMIN UI'],
      }),
      createCampaign: build.mutation<CreateCampaignApiResponse, CreateCampaignApiArg>({
        query: (queryArg) => ({
          url: `/manage/campaigns`,
          method: 'POST',
          body: queryArg.campaignItemForCreation,
        }),
        invalidatesTags: ['CAMPAIGNS', 'CREATE CAMPAIGN CONTENT PAGE', 'ADMIN UI'],
      }),
      updateOneCampaign: build.mutation<UpdateOneCampaignApiResponse, UpdateOneCampaignApiArg>({
        query: (queryArg) => ({
          url: `/manage/campaigns/${queryArg.campaignId}`,
          method: 'PATCH',
          body: queryArg.campaignItemForUpdate,
        }),
        invalidatesTags: [
          'CAMPAIGNS',
          'CREATE CAMPAIGN CONTENT PAGE',
          'CREATE CAMPAIGN FINISH PAGE',
          'MANAGE CAMPAIGNS PAGE',
          'ADMIN UI',
        ],
      }),
      deleteManageCampaignsByCampaignId: build.mutation<
        DeleteManageCampaignsByCampaignIdApiResponse,
        DeleteManageCampaignsByCampaignIdApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/campaigns/${queryArg.campaignId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['CAMPAIGNS', 'ADMIN UI'],
      }),
      getManageCampaignsByCampaignId: build.query<
        GetManageCampaignsByCampaignIdApiResponse,
        GetManageCampaignsByCampaignIdApiArg
      >({
        query: (queryArg) => ({ url: `/manage/campaigns/${queryArg.campaignId}` }),
        providesTags: ['CAMPAIGNS'],
      }),
      updateOneCampaignStatus: build.mutation<
        UpdateOneCampaignStatusApiResponse,
        UpdateOneCampaignStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/campaigns/${queryArg.campaignId}/status`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['ADMIN UI', 'CAMPAIGNS', 'CAMPAIGN DETAIL PAGE'],
      }),
      getManageStatisticsSocialNetworksFilterByTargeting: build.query<
        GetManageStatisticsSocialNetworksFilterByTargetingApiResponse,
        GetManageStatisticsSocialNetworksFilterByTargetingApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/statistics/social-networks/filter-by-targeting`,
          params: {
            gender: queryArg.gender,
            campaignsIncluded: queryArg.campaignsIncluded,
            campaignsExcluded: queryArg.campaignsExcluded,
            engagementRate: queryArg.engagementRate,
            rating: queryArg.rating,
            interests: queryArg.interests,
            ages: queryArg.ages,
            instagramMinFollowers: queryArg.instagramMinFollowers,
            instagramMaxFollowers: queryArg.instagramMaxFollowers,
            youtubeMinFollowers: queryArg.youtubeMinFollowers,
            youtubeMaxFollowers: queryArg.youtubeMaxFollowers,
            tikTokMinFollowers: queryArg.tikTokMinFollowers,
            tikTokMaxFollowers: queryArg.tikTokMaxFollowers,
            blogMinFollowers: queryArg.blogMinFollowers,
            blogMaxFollowers: queryArg.blogMaxFollowers,
            countryIncluded: queryArg.countryIncluded,
            countryExcluded: queryArg.countryExcluded,
            regionsIncluded: queryArg.regionsIncluded,
            regionsExcluded: queryArg.regionsExcluded,
            cityIncluded: queryArg.cityIncluded,
            cityExcluded: queryArg.cityExcluded,
            clubsExcluded: queryArg.clubsExcluded,
            clubsIncluded: queryArg.clubsIncluded,
            usersIncluded: queryArg.usersIncluded,
            usersExcluded: queryArg.usersExcluded,
          },
        }),
        providesTags: [
          'STATISTICS',
          'CAMPAIGNS',
          'CREATE CAMPAIGN TARGETING PAGE',
          'SOCIAL NETWORKS',
          'USERS',
          'ADMIN UI',
        ],
      }),
      postManageStatisticsSocialNetworksFilterByTargeting: build.mutation<
        PostManageStatisticsSocialNetworksFilterByTargetingApiResponse,
        PostManageStatisticsSocialNetworksFilterByTargetingApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/statistics/social-networks/filter-by-targeting`,
          method: 'POST',
          body: queryArg.targetingBody,
          params: { campaignId: queryArg.campaignId },
        }),
        invalidatesTags: ['STATISTICS', 'ADMIN UI', 'CREATE CAMPAIGN TARGETING PAGE'],
      }),
      getIncludedUsersListByFilter: build.query<
        GetIncludedUsersListByFilterApiResponse,
        GetIncludedUsersListByFilterApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/find-by-filter/list-of-users`,
          params: {
            includedUsersList: queryArg.includedUsersList,
            gender: queryArg.gender,
            campaignsIncluded: queryArg.campaignsIncluded,
            campaignsExcluded: queryArg.campaignsExcluded,
            engagementRate: queryArg.engagementRate,
            rating: queryArg.rating,
            interests: queryArg.interests,
            ages: queryArg.ages,
            instagramMinFollowers: queryArg.instagramMinFollowers,
            instagramMaxFollowers: queryArg.instagramMaxFollowers,
            youtubeMaxFollowers: queryArg.youtubeMaxFollowers,
            tikTokMinFollowers: queryArg.tikTokMinFollowers,
            tikTokMaxFollowers: queryArg.tikTokMaxFollowers,
            blogMinFollowers: queryArg.blogMinFollowers,
            blogMaxFollowers: queryArg.blogMaxFollowers,
            countryIncluded: queryArg.countryIncluded,
            countryExcluded: queryArg.countryExcluded,
            regionsIncluded: queryArg.regionsIncluded,
            regionsExcluded: queryArg.regionsExcluded,
            cityIncluded: queryArg.cityIncluded,
            cityExcluded: queryArg.cityExcluded,
            usersIncluded: queryArg.usersIncluded,
            usersExcluded: queryArg.usersExcluded,
          },
        }),
        providesTags: ['USERS', 'CAMPAIGNS', 'CREATE CAMPAIGN TARGETING PAGE', 'ADMIN UI'],
      }),
      getManageCampaignsByCampaignIdTargeting: build.query<
        GetManageCampaignsByCampaignIdTargetingApiResponse,
        GetManageCampaignsByCampaignIdTargetingApiArg
      >({
        query: (queryArg) => ({ url: `/manage/campaigns/${queryArg.pathCampaignId}/targeting` }),
        providesTags: ['CAMPAIGNS', 'CREATE CAMPAIGN TARGETING PAGE', 'ADMIN UI'],
      }),
      postManageCampaignsByCampaignIdTargeting: build.mutation<
        PostManageCampaignsByCampaignIdTargetingApiResponse,
        PostManageCampaignsByCampaignIdTargetingApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/campaigns/${queryArg.pathCampaignId}/targeting`,
          method: 'POST',
          body: queryArg.targetingBody,
        }),
        invalidatesTags: ['CAMPAIGNS', 'CREATE CAMPAIGN TARGETING PAGE', 'ADMIN UI'],
      }),
      updateFilterInformationForTargeting: build.mutation<
        UpdateFilterInformationForTargetingApiResponse,
        UpdateFilterInformationForTargetingApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/campaigns/${queryArg.pathCampaignId}/targeting`,
          method: 'PATCH',
          body: queryArg.targetingBody,
        }),
        invalidatesTags: ['CAMPAIGNS', 'CREATE CAMPAIGN TARGETING PAGE', 'ADMIN UI'],
      }),
      getManageUsersByUserIdOrdersStatisticsAndOrderId: build.query<
        GetManageUsersByUserIdOrdersStatisticsAndOrderIdApiResponse,
        GetManageUsersByUserIdOrdersStatisticsAndOrderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/orders-statistics/${queryArg.orderId}`,
        }),
        providesTags: ['STATISTICS', 'CHAT PAGE', 'MANAGE CAMPAIGNS PAGE', 'USERS', 'ADMIN UI'],
      }),
      getOrdersStatus: build.query<GetOrdersStatusApiResponse, GetOrdersStatusApiArg>({
        query: (queryArg) => ({
          url: `/manage/orders/status/count`,
          params: { brandId: queryArg.brandId, campaignId: queryArg.campaignId },
        }),
        providesTags: [
          'ORDERS',
          'MANAGE CAMPAIGN CONTENT EXPANDED PAGE',
          'MANAGE CAMPAIGNS PAGE',
          'ADMIN UI',
        ],
      }),
      getOrders: build.query<GetOrdersApiResponse, GetOrdersApiArg>({
        query: (queryArg) => ({
          url: `/manage/orders`,
          params: {
            offset: queryArg.offset,
            search: queryArg.search,
            archived: queryArg.archived,
            limit: queryArg.limit,
            status: queryArg.status,
            brandId: queryArg.brandId,
            campaignId: queryArg.campaignId,
          },
        }),
        providesTags: [
          'ORDERS',
          'MANAGE CAMPAIGN CONTENT EXPANDED PAGE',
          'MANAGE CAMPAIGNS PAGE',
          'ADMIN UI',
        ],
      }),
      postManageOrders: build.mutation<PostManageOrdersApiResponse, PostManageOrdersApiArg>({
        query: (queryArg) => ({ url: `/manage/orders`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['ORDERS', 'CAMPAIGN REQUEST PAGE', 'USER UI'],
      }),
      updateOrder: build.mutation<UpdateOrderApiResponse, UpdateOrderApiArg>({
        query: (queryArg) => ({
          url: `/manage/orders/${queryArg.pathOrderId}`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['ORDERS', 'ADMIN UI', 'USER UI', 'MY ORDERS PAGE'],
      }),
      deleteManageOrdersByOrderId: build.mutation<
        DeleteManageOrdersByOrderIdApiResponse,
        DeleteManageOrdersByOrderIdApiArg
      >({
        query: (queryArg) => ({ url: `/manage/orders/${queryArg.orderId}`, method: 'DELETE' }),
        invalidatesTags: ['ORDERS', 'MY ORDERS PAGE', 'USER UI'],
      }),
      patchManageMediasByMediaId: build.mutation<
        PatchManageMediasByMediaIdApiResponse,
        PatchManageMediasByMediaIdApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/medias/${queryArg.mediaId}`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'MANAGE CAMPAIGNS PAGE', 'ORDERS', 'ADMIN UI'],
      }),
      getManageCampaignsByCampaignIdReporting: build.query<
        GetManageCampaignsByCampaignIdReportingApiResponse,
        GetManageCampaignsByCampaignIdReportingApiArg
      >({
        query: (queryArg) => ({ url: `/manage/campaigns/${queryArg.campaignId}/reporting` }),
        providesTags: ['CAMPAIGNS', 'CAMPAIGN REPORTING PAGE', 'ADMIN UI'],
      }),
      getManageUsersByUserId: build.query<
        GetManageUsersByUserIdApiResponse,
        GetManageUsersByUserIdApiArg
      >({
        query: (queryArg) => ({ url: `/manage/users/${queryArg.userId}` }),
        providesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER EDIT ADDRESS PAGE',
          'EDIT USER EDIT RATINGS PAGE',
          'EDIT USER GENERAL PAGE',
          'EDIT USER NOTIFICATIONS PAGE',
          'EDIT USER RATINGS OVERVIEW PAGE',
          'EDIT USER SOCIAL NETWORK LINKS PAGE',
          'EDIT USER SOCIAL NETWORK STATISITCS',
          'USER ACCOUNT PAGE',
        ],
      }),
      getManageUsersUserlist: build.query<
        GetManageUsersUserlistApiResponse,
        GetManageUsersUserlistApiArg
      >({
        query: (queryArg) => ({ url: `/manage/users/userlist`, params: { users: queryArg.users } }),
        providesTags: ['ADMIN UI', 'CREATE CAMPAIGN TARGETING PAGE'],
      }),
      patchManageUsersByUserIdGeneral: build.mutation<
        PatchManageUsersByUserIdGeneralApiResponse,
        PatchManageUsersByUserIdGeneralApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/general`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER GENERAL PAGE',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      patchManageUsersByUserIdNotifications: build.mutation<
        PatchManageUsersByUserIdNotificationsApiResponse,
        PatchManageUsersByUserIdNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/notifications`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER NOTIFICATIONS PAGE',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      patchManageUsersByUserIdSocialNetworkLinks: build.mutation<
        PatchManageUsersByUserIdSocialNetworkLinksApiResponse,
        PatchManageUsersByUserIdSocialNetworkLinksApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/social-network-links`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['USERS', 'ADMIN UI', 'EDIT USER SOCIAL NETWORK LINKS PAGE'],
      }),
      patchManageUsersByUserIdPassword: build.mutation<
        PatchManageUsersByUserIdPasswordApiResponse,
        PatchManageUsersByUserIdPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/password`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['USERS', 'ADMIN UI', 'EDIT USER CHANGE PASSWORD PAGE'],
      }),
      patchManageUsersByUserIdStatus: build.mutation<
        PatchManageUsersByUserIdStatusApiResponse,
        PatchManageUsersByUserIdStatusApiArg
      >({
        query: (queryArg) => ({
          url: `manage/users/${queryArg.userId}/status`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['USERS', 'DASHBOARD PAGE', 'EDIT USER GENERAL PAGE', 'ADMIN UI'],
      }),
      patchManageUsersByUserIdLang: build.mutation<
        PatchManageUsersByUserIdLangApiResponse,
        PatchManageUsersByUserIdLangApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/lang`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['USERS', 'ADMIN UI', 'USER UI'],
      }),
      patchManageUsersByUserIdEmail: build.mutation<
        PatchManageUsersByUserIdEmailApiResponse,
        PatchManageUsersByUserIdEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/email`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['USERS', 'USER ACCOUNT PAGE', 'USER UI', 'ADMIN UI'],
      }),
      createNewAddressForOneUser: build.mutation<
        CreateNewAddressForOneUserApiResponse,
        CreateNewAddressForOneUserApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/addresses`,
          method: 'POST',
          body: queryArg.addressItemPost,
        }),
        invalidatesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER ADD ADDRESS PAGE',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      updateOneUserAddress: build.mutation<
        UpdateOneUserAddressApiResponse,
        UpdateOneUserAddressApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/addresses/${queryArg.addressId}`,
          method: 'PUT',
          body: queryArg.addressItem,
        }),
        invalidatesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER EDIT ADDRESS PAGE',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      deleteOneUserAddress: build.mutation<
        DeleteOneUserAddressApiResponse,
        DeleteOneUserAddressApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/addresses/${queryArg.addressId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER GENERAL PAGE',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      getUserRatings: build.query<GetUserRatingsApiResponse, GetUserRatingsApiArg>({
        query: (queryArg) => ({ url: `/manage/users/${queryArg.userId}/ratings` }),
        providesTags: ['USERS', 'EDIT USER RATINGS OVERVIEW PAGE', 'ADMIN UI'],
      }),
      createRating: build.mutation<CreateRatingApiResponse, CreateRatingApiArg>({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/ratings`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['USERS', 'EDIT USER ADD RATINGS PAGE', 'ADMIN UI'],
      }),
      getbrandsWithCampaignsForAddUserRatings: build.query<
        GetbrandsWithCampaignsForAddUserRatingsApiResponse,
        GetbrandsWithCampaignsForAddUserRatingsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/ratings/brands-with-campaigns`,
        }),
        providesTags: ['USERS', 'EDIT USER RATINGS OVERVIEW PAGE', 'ADMIN UI'],
      }),
      getManageUsersByUserIdRatingsAndRatingId: build.query<
        GetManageUsersByUserIdRatingsAndRatingIdApiResponse,
        GetManageUsersByUserIdRatingsAndRatingIdApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/ratings/${queryArg.ratingId}`,
        }),
        providesTags: ['USERS', 'EDIT USER EDIT RATINGS PAGE', 'ADMIN UI'],
      }),
      patchManageUsersByUserIdRatingsAndRatingId: build.mutation<
        PatchManageUsersByUserIdRatingsAndRatingIdApiResponse,
        PatchManageUsersByUserIdRatingsAndRatingIdApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/ratings/${queryArg.ratingId}`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['USERS', 'EDIT USER EDIT RATINGS PAGE', 'ADMIN UI'],
      }),
      deleteManageUsersByUserIdRatingsAndRatingId: build.mutation<
        DeleteManageUsersByUserIdRatingsAndRatingIdApiResponse,
        DeleteManageUsersByUserIdRatingsAndRatingIdApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/ratings/${queryArg.ratingId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          'USERS',
          'EDIT USER EDIT RATINGS PAGE',
          'EDIT USER RATINGS OVERVIEW PAGE',
          'ADMIN UI',
        ],
      }),
      getBrands: build.query<GetBrandsApiResponse, GetBrandsApiArg>({
        query: () => ({ url: `/manage/brands` }),
        providesTags: ['BRANDS', 'BRAND LIST PAGE', 'ADMIN UI'],
      }),
      createBrands: build.mutation<CreateBrandsApiResponse, CreateBrandsApiArg>({
        query: (queryArg) => ({
          url: `/manage/brands`,
          method: 'POST',
          body: queryArg.brandPostPatchItem,
        }),
        invalidatesTags: ['BRANDS', 'CREATE BRAND PAGE', 'ADMIN UI'],
      }),
      getManageBrandsBrandsList: build.query<
        GetManageBrandsBrandsListApiResponse,
        GetManageBrandsBrandsListApiArg
      >({
        query: () => ({ url: `/manage/brands/brands-list` }),
        providesTags: ['BRANDS', 'CREATE CAMPAIGN CONTENT PAGE', 'ADMIN UI'],
      }),
      getOneBrand: build.query<GetOneBrandApiResponse, GetOneBrandApiArg>({
        query: (queryArg) => ({ url: `/manage/brands/${queryArg.pathBrandId}` }),
        providesTags: [
          'BRANDS',
          'EDIT BRAND NOTIFICATIONS PAGE',
          'EDIT BRAND PAGE',
          'EDIT BRAND SOCIAL NETWORK LINKS PAGE',
          'ADMIN UI',
        ],
      }),
      updateBrand: build.mutation<UpdateBrandApiResponse, UpdateBrandApiArg>({
        query: (queryArg) => ({
          url: `/manage/brands/${queryArg.pathBrandId}`,
          method: 'PATCH',
          body: queryArg.brandPostPatchItem,
        }),
        invalidatesTags: ['BRANDS', 'EDIT BRAND PAGE', 'ADMIN UI'],
      }),
      deleteManageBrandsByBrandId: build.mutation<
        DeleteManageBrandsByBrandIdApiResponse,
        DeleteManageBrandsByBrandIdApiArg
      >({
        query: (queryArg) => ({ url: `/manage/brands/${queryArg.brandId}`, method: 'DELETE' }),
      }),
      patchManageBrandsByBrandIdNotifications: build.mutation<
        PatchManageBrandsByBrandIdNotificationsApiResponse,
        PatchManageBrandsByBrandIdNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/brands/${queryArg.brandId}/notifications`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['BRANDS', 'EDIT BRAND NOTIFICATIONS PAGE', 'ADMIN UI'],
      }),
      patchManageBrandsByBrandIdSocialNetworkLinks: build.mutation<
        PatchManageBrandsByBrandIdSocialNetworkLinksApiResponse,
        PatchManageBrandsByBrandIdSocialNetworkLinksApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/brands/${queryArg.brandId}/social-network-links`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['BRANDS', 'EDIT BRAND SOCIAL NETWORK LINKS PAGE', 'ADMIN UI'],
      }),
      updateOneBrandPassword: build.mutation<
        UpdateOneBrandPasswordApiResponse,
        UpdateOneBrandPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/brands/${queryArg.pathBrandId}/password`,
          method: 'PATCH',
          body: queryArg.body,
          params: { 'send-to-brand': queryArg['send-to-brand'] },
        }),
        invalidatesTags: ['BRANDS', 'EDIT BRAND CHANGE PASSWORD PAGE', 'ADMIN UI'],
      }),
      getClubs: build.query<GetClubsApiResponse, GetClubsApiArg>({
        query: () => ({ url: `/manage/clubs` }),
        providesTags: ['CLUBS', 'MANAGEMENT CLUBS PAGE', 'ADMIN UI'],
      }),
      postClubs: build.mutation<PostClubsApiResponse, PostClubsApiArg>({
        query: (queryArg) => ({
          url: `/manage/clubs`,
          method: 'POST',
          body: queryArg.clubItemForPost,
        }),
        invalidatesTags: ['CLUBS', 'CREATE CLUB PAGE', 'ADMIN UI'],
      }),
      getLanguages: build.query<GetLanguagesApiResponse, GetLanguagesApiArg>({
        query: () => ({ url: `/manage/languages` }),
        providesTags: ['CLUBS', 'MANAGEMENT CLUBS PAGE', 'ADMIN UI'],
      }),
      postLanguages: build.mutation<PostLanguagesApiResponse, PostLanguagesApiArg>({
        query: (queryArg) => ({
          url: `/manage/languages`,
          method: 'POST',
          body: queryArg.clubItemForPost,
        }),
        invalidatesTags: ['CLUBS', 'CREATE CLUB PAGE', 'ADMIN UI'],
      }),
      patchManageClubsByClubId: build.mutation<
        PatchManageClubsByClubIdApiResponse,
        PatchManageClubsByClubIdApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/clubs/${queryArg.clubId}`,
          method: 'PATCH',
          body: queryArg.clubItemForPost,
        }),
        invalidatesTags: ['CLUBS', 'EDIT CLUB PAGE', 'ADMIN UI'],
      }),
      getManageClubsByClubId: build.query<
        GetManageClubsByClubIdApiResponse,
        GetManageClubsByClubIdApiArg
      >({
        query: (queryArg) => ({ url: `/manage/clubs/${queryArg.clubId}` }),
        providesTags: ['CLUBS', 'EDIT CLUB PAGE', 'ADMIN UI'],
      }),
      deleteManageClubsByClubId: build.mutation<
        DeleteManageClubsByClubIdApiResponse,
        DeleteManageClubsByClubIdApiArg
      >({
        query: (queryArg) => ({ url: `/manage/clubs/${queryArg.clubId}`, method: 'DELETE' }),
        invalidatesTags: ['CLUBS', 'MANAGEMENT CLUBS PAGE', 'ADMIN UI'],
      }),
      getManageClubsByClubIdStatistics: build.query<
        GetManageClubsByClubIdStatisticsApiResponse,
        GetManageClubsByClubIdStatisticsApiArg
      >({
        query: (queryArg) => ({ url: `/manage/clubs/${queryArg.clubId}/statistics` }),
        providesTags: ['CLUBS', 'MANAGEMENT CLUBS PAGE', 'ADMIN UI'],
      }),
      getManageClubsByClubIdUsers: build.query<
        GetManageClubsByClubIdUsersApiResponse,
        GetManageClubsByClubIdUsersApiArg
      >({
        query: (queryArg) => ({ url: `/manage/clubs/${queryArg.clubId}/users` }),
        providesTags: ['CLUBS', 'MANAGEMENT CLUBS PAGE', 'USERS', 'ADMIN UI'],
      }),
      patchManageClubsByClubIdUsers: build.mutation<
        PatchManageClubsByClubIdUsersApiResponse,
        PatchManageClubsByClubIdUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/clubs/${queryArg.clubId}/users`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['CLUBS', 'MANAGEMENT CLUBS PAGE', 'ADMIN UI'],
      }),
      getManageChatConversations: build.query<
        GetManageChatConversationsApiResponse,
        GetManageChatConversationsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/chat/conversations`,
          params: { search: queryArg.search, limit: queryArg.limit, cursor: queryArg.cursor },
        }),
        providesTags: ['CHAT', 'CHAT PAGE', 'ADMIN UI'],
      }),
      deleteManageChatConversations: build.mutation<
        DeleteManageChatConversationsApiResponse,
        DeleteManageChatConversationsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/chat/conversations`,
          method: 'DELETE',
          body: queryArg.body,
        }),
        invalidatesTags: ['ADMIN UI', 'CHAT'],
      }),
      postManageChatNewConversation: build.mutation<
        PostManageChatNewConversationApiResponse,
        PostManageChatNewConversationApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/chat/new-conversation`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['CHAT', 'CHAT PAGE', 'ADMIN UI'],
      }),
      getChatUsersByUserIdConversationsAndConversationIdMessages: build.query<
        GetChatUsersByUserIdConversationsAndConversationIdMessagesApiResponse,
        GetChatUsersByUserIdConversationsAndConversationIdMessagesApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/users/${queryArg.userId}/conversations/${queryArg.conversationId}/messages`,
          params: { limit: queryArg.limit, cursor: queryArg.cursor },
        }),
        providesTags: ['CHAT', 'ADMIN UI', 'CHAT PAGE', 'USER UI'],
      }),
      postChatUsersByUserIdConversationsAndConversationIdMessages: build.mutation<
        PostChatUsersByUserIdConversationsAndConversationIdMessagesApiResponse,
        PostChatUsersByUserIdConversationsAndConversationIdMessagesApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/users/${queryArg.userId}/conversations/${queryArg.conversationId}/messages`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['CHAT', 'ADMIN UI', 'CHAT PAGE', 'USER UI'],
      }),
      getManageChatConversationsByConversationIdMessages: build.query<
        GetManageChatConversationsByConversationIdMessagesApiResponse,
        GetManageChatConversationsByConversationIdMessagesApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/chat/conversations/${queryArg.conversationId}/messages`,
          params: { limit: queryArg.limit, cursor: queryArg.cursor },
        }),
        providesTags: ['CHAT', 'ADMIN UI', 'CHAT PAGE', 'USER UI'],
      }),
      postManageChatConversationsByConversationIdMessages: build.mutation<
        PostManageChatConversationsByConversationIdMessagesApiResponse,
        PostManageChatConversationsByConversationIdMessagesApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/chat/conversations/${queryArg.conversationId}/messages`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['CHAT', 'ADMIN UI', 'CHAT PAGE', 'USER UI'],
      }),
      postManageChatConversationsByConversationIdMarkAsRead: build.mutation<
        PostManageChatConversationsByConversationIdMarkAsReadApiResponse,
        PostManageChatConversationsByConversationIdMarkAsReadApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/chat/conversations/${queryArg.conversationId}/mark-as-read`,
          method: 'POST',
        }),
        invalidatesTags: ['CHAT', 'ADMIN UI', 'CHAT PAGE'],
      }),
      getManageInterests: build.query<GetManageInterestsApiResponse, GetManageInterestsApiArg>({
        query: () => ({ url: `/manage/interests` }),
        providesTags: [
          'USERS',
          'ADMIN UI',
          'CREATE CAMPAIGN TARGETING PAGE',
          'CREATE USER PAGE',
          'EDIT USER GENERAL PAGE',
          'USER UI',
        ],
      }),
      postManageInterests: build.mutation<
        PostManageInterestsApiResponse,
        PostManageInterestsApiArg
      >({
        query: () => ({ url: `/manage/interests`, method: 'POST' }),
      }),
      getManageNotifications: build.query<
        GetManageNotificationsApiResponse,
        GetManageNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/notifications/`,
          params: { limit: queryArg.limit, cursor: queryArg.cursor },
        }),
        providesTags: ['NOTIFICATIONS', 'ADMIN UI', 'USERS'],
      }),
      putManageNotificationsRead: build.mutation<
        PutManageNotificationsReadApiResponse,
        PutManageNotificationsReadApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/notifications/read`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['NOTIFICATIONS', 'USERS', 'ADMIN UI', 'USER UI'],
      }),
      getManageIndustries: build.query<GetManageIndustriesApiResponse, GetManageIndustriesApiArg>({
        query: () => ({ url: `/manage/industries` }),
        providesTags: ['BRANDS', 'CREATE BRAND PAGE', 'EDIT BRAND PAGE', 'ADMIN UI'],
      }),
      postManageIndustries: build.mutation<
        PostManageIndustriesApiResponse,
        PostManageIndustriesApiArg
      >({
        query: () => ({ url: `/manage/industries`, method: 'POST' }),
      }),
      getManageTags: build.query<GetManageTagsApiResponse, GetManageTagsApiArg>({
        query: () => ({ url: `/manage/tags` }),
        providesTags: ['USERS', 'ADMIN UI', 'EDIT USER GENERAL PAGE', 'CREATE USER PAGE'],
      }),
      getManageClubsClubsList: build.query<
        GetManageClubsClubsListApiResponse,
        GetManageClubsClubsListApiArg
      >({
        query: () => ({ url: `/manage/clubs/clubs-list` }),
        providesTags: ['CLUBS', 'ADMIN UI', 'CREATE USER PAGE', 'EDIT USER GENERAL PAGE', 'USERS'],
      }),
      getManageChatPossibleConversations: build.query<
        GetManageChatPossibleConversationsApiResponse,
        GetManageChatPossibleConversationsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/chat/possible-conversations`,
          params: { search: queryArg.search },
        }),
        providesTags: ['CHAT', 'ADMIN UI', 'CHAT PAGE'],
      }),
      getManageUsersByUserIdOrdersAndOrderIdMediasStatistics: build.query<
        GetManageUsersByUserIdOrdersAndOrderIdMediasStatisticsApiResponse,
        GetManageUsersByUserIdOrdersAndOrderIdMediasStatisticsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/orders/${queryArg.orderId}/medias-statistics`,
        }),
        providesTags: ['STATISTICS', 'ADMIN UI', 'SOCIAL NETWORKS', 'USERS'],
      }),
      getManageUsersByUserIdIgMedias: build.query<
        GetManageUsersByUserIdIgMediasApiResponse,
        GetManageUsersByUserIdIgMediasApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/ig-medias`,
          params: {
            pagination: queryArg.pagination,
            'get-fresh-data': queryArg['get-fresh-data'],
            cursor: queryArg.cursor,
          },
        }),
        providesTags: ['MEDIAS', 'MY ORDERS ADD IG MEDIA PAGE', 'ORDERS', 'USER UI'],
      }),
      getManageUsersByUserIdIgStories: build.query<
        GetManageUsersByUserIdIgStoriesApiResponse,
        GetManageUsersByUserIdIgStoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/ig-stories`,
          params: {
            pagination: queryArg.pagination,
            'get-fresh-data': queryArg['get-fresh-data'],
            cursor: queryArg.cursor,
          },
        }),
        providesTags: ['ADMIN UI', 'EDIT USER SOCIAL NETWORK STATISITCS'],
      }),
      getManageUsersByUserIdYoutube: build.query<
        GetManageUsersByUserIdYoutubeApiResponse,
        GetManageUsersByUserIdYoutubeApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/youtube`,
          params: { pagination: queryArg.pagination, 'get-fresh-data': queryArg['get-fresh-data'] },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD YOUTUBE VIDEO PAGE'],
      }),
      getManageUsersByUserIdYoutubeShorts: build.query<
        GetManageUsersByUserIdYoutubeShortsApiResponse,
        GetManageUsersByUserIdYoutubeShortsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/youtube-shorts`,
          params: { pagination: queryArg.pagination, 'get-fresh-data': queryArg['get-fresh-data'] },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD YOUTUBE SHORTS PAGE'],
      }),
      getManageUsersByUserIdTiktok: build.query<
        GetManageUsersByUserIdTiktokApiResponse,
        GetManageUsersByUserIdTiktokApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/tiktok`,
          params: { pagination: queryArg.pagination, 'get-fresh-data': queryArg['get-fresh-data'] },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI'],
      }),
      postManageUpload: build.mutation<PostManageUploadApiResponse, PostManageUploadApiArg>({
        query: (queryArg) => ({ url: `/manage/upload`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['UPLOAD', 'CREATE CAMPAIGN CONTENT PAGE', 'ADMIN UI'],
      }),
      postManageRefreshToken: build.mutation<
        PostManageRefreshTokenApiResponse,
        PostManageRefreshTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/refresh-token`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['AUTH', 'ADMIN UI', 'USER UI'],
      }),
      postManageResetPassword: build.mutation<
        PostManageResetPasswordApiResponse,
        PostManageResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/reset-password`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['AUTH', 'USER UI', 'ADMIN UI'],
      }),
      getManageChatConversationsByConversationIdFiles: build.query<
        GetManageChatConversationsByConversationIdFilesApiResponse,
        GetManageChatConversationsByConversationIdFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/chat/conversations/${queryArg.conversationId}/files`,
        }),
        providesTags: ['CHAT', 'ADMIN UI', 'CHAT PAGE'],
      }),
      postManageChatUpload: build.mutation<
        PostManageChatUploadApiResponse,
        PostManageChatUploadApiArg
      >({
        query: (queryArg) => ({ url: `/manage/chat/upload`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['CHAT', 'ADMIN UI', 'USER UI', 'CHAT PAGE'],
      }),
      getManageUsersByUserIdGhostLogin: build.query<
        GetManageUsersByUserIdGhostLoginApiResponse,
        GetManageUsersByUserIdGhostLoginApiArg
      >({
        query: (queryArg) => ({ url: `/manage/users/${queryArg.userId}/ghost-login` }),
        providesTags: ['AUTH', 'USERS', 'ADMIN UI'],
      }),
      getManageUsersByUserIdOrders: build.query<
        GetManageUsersByUserIdOrdersApiResponse,
        GetManageUsersByUserIdOrdersApiArg
      >({
        query: (queryArg) => ({ url: `/manage/users/${queryArg.userId}/orders` }),
        providesTags: ['ORDERS'],
      }),
      postManageUsersRegistration: build.mutation<
        PostManageUsersRegistrationApiResponse,
        PostManageUsersRegistrationApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/registration`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
      getManage: build.query<GetManageApiResponse, GetManageApiArg>({
        query: () => ({ url: `/manage` }),
      }),
      postManageChatAuth: build.mutation<PostManageChatAuthApiResponse, PostManageChatAuthApiArg>({
        query: (queryArg) => ({ url: `/manage/chat/auth`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['ADMIN UI'],
      }),
      getManageNotificationsAuth: build.query<
        GetManageNotificationsAuthApiResponse,
        GetManageNotificationsAuthApiArg
      >({
        query: () => ({ url: `/manage/notifications/auth` }),
        providesTags: ['ADMIN UI'],
      }),
      postManageNotificationsAuth: build.mutation<
        PostManageNotificationsAuthApiResponse,
        PostManageNotificationsAuthApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/notifications/auth`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
      getManageNotificationsPusherBeamsAuth: build.query<
        GetManageNotificationsPusherBeamsAuthApiResponse,
        GetManageNotificationsPusherBeamsAuthApiArg
      >({
        query: () => ({ url: `/manage/notifications/pusher/beams-auth` }),
      }),
      getManagePermissionsGroupList: build.query<
        GetManagePermissionsGroupListApiResponse,
        GetManagePermissionsGroupListApiArg
      >({
        query: () => ({ url: `/manage/permissions/group-list` }),
        providesTags: ['PERMISSION'],
      }),
      getManagePermissionsBrandList: build.query<
        GetManagePermissionsBrandListApiResponse,
        GetManagePermissionsBrandListApiArg
      >({
        query: () => ({ url: `/manage/permissions/brand-list` }),
        providesTags: ['PERMISSION'],
      }),
      getManagePermissionsCampaignList: build.query<
        GetManagePermissionsCampaignListApiResponse,
        GetManagePermissionsCampaignListApiArg
      >({
        query: () => ({ url: `/manage/permissions/campaign-list` }),
        providesTags: ['PERMISSION'],
      }),
      patchManagePermissionsAssign: build.mutation<
        PatchManagePermissionsAssignApiResponse,
        PatchManagePermissionsAssignApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/permissions/assign`,
          method: 'PATCH',
          body: queryArg.listOfPermissionsPatchedForUser,
        }),
        invalidatesTags: ['PERMISSION'],
      }),
      getManagePermissions: build.query<
        GetManagePermissionsApiResponse,
        GetManagePermissionsApiArg
      >({
        query: () => ({ url: `/manage/permissions` }),
        providesTags: ['PERMISSION'],
      }),
      getManagePermissionsByUserId: build.query<
        GetManagePermissionsByUserIdApiResponse,
        GetManagePermissionsByUserIdApiArg
      >({
        query: (queryArg) => ({ url: `/manage/permissions/${queryArg.userId}` }),
        providesTags: ['PERMISSION'],
      }),
      postManageOrdersExportAddressesByCampaignId: build.mutation<
        PostManageOrdersExportAddressesByCampaignIdApiResponse,
        PostManageOrdersExportAddressesByCampaignIdApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/orders/export/addresses/${queryArg.campaignId}`,
          method: 'POST',
        }),
        invalidatesTags: ['CAMPAIGNS'],
      }),
      postManageSignup: build.mutation<PostManageSignupApiResponse, PostManageSignupApiArg>({
        query: (queryArg) => ({ url: `/manage/signup`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['SECONDARY ADMIN', 'AUTH'],
      }),
      postManageSignupCheckEmail: build.mutation<
        PostManageSignupCheckEmailApiResponse,
        PostManageSignupCheckEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/signup/check-email`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['SECONDARY ADMIN', 'AUTH'],
      }),
      postManageSignupGeneral: build.mutation<
        PostManageSignupGeneralApiResponse,
        PostManageSignupGeneralApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/signup/general`,
          method: 'POST',
          body: queryArg.adminItemForPostRegistrationGeneral,
        }),
        invalidatesTags: ['SECONDARY ADMIN', 'AUTH'],
      }),
      postManageSignupResendEmailCode: build.mutation<
        PostManageSignupResendEmailCodeApiResponse,
        PostManageSignupResendEmailCodeApiArg
      >({
        query: () => ({ url: `/manage/signup/resend-email-code`, method: 'POST' }),
        invalidatesTags: ['AUTH', 'SECONDARY ADMIN'],
      }),
      postManageSignupCheckPhoneNumber: build.mutation<
        PostManageSignupCheckPhoneNumberApiResponse,
        PostManageSignupCheckPhoneNumberApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/signup/check-phone-number`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['AUTH'],
      }),
      postManageSignupResendPhoneCode: build.mutation<
        PostManageSignupResendPhoneCodeApiResponse,
        PostManageSignupResendPhoneCodeApiArg
      >({
        query: () => ({ url: `/manage/signup/resend-phone-code`, method: 'POST' }),
        invalidatesTags: ['AUTH'],
      }),
      getManageAdmins: build.query<GetManageAdminsApiResponse, GetManageAdminsApiArg>({
        query: (queryArg) => ({
          url: `/manage/admins`,
          params: {
            limit: queryArg.limit,
            search: queryArg.search,
            filterStatus: queryArg.filterStatus,
            order: queryArg.order,
            orderBy: queryArg.orderBy,
          },
        }),
      }),
      patchManageSignupPhoneNumber: build.mutation<
        PatchManageSignupPhoneNumberApiResponse,
        PatchManageSignupPhoneNumberApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/signup/phone-number`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['AUTH'],
      }),
      getManageToValidate: build.query<GetManageToValidateApiResponse, GetManageToValidateApiArg>({
        query: () => ({ url: `/manage/to-validate` }),
      }),
      patchManageUsersByUserIdAdminStatus: build.mutation<
        PatchManageUsersByUserIdAdminStatusApiResponse,
        PatchManageUsersByUserIdAdminStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/admin-status`,
          method: 'PATCH',
          body: queryArg.body,
        }),
      }),
      postManageConsent: build.mutation<PostManageConsentApiResponse, PostManageConsentApiArg>({
        query: (queryArg) => ({ url: `/manage/consent`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['USERS'],
      }),
      patchManageOnboarding: build.mutation<
        PatchManageOnboardingApiResponse,
        PatchManageOnboardingApiArg
      >({
        query: (queryArg) => ({ url: `/manage/onboarding`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['USERS'],
      }),
      getManageCampaignsAdmins: build.query<
        GetManageCampaignsAdminsApiResponse,
        GetManageCampaignsAdminsApiArg
      >({
        query: () => ({ url: `/manage/campaigns/admins` }),
      }),
      patchManageOrdersByOrderIdStatus: build.mutation<
        PatchManageOrdersByOrderIdStatusApiResponse,
        PatchManageOrdersByOrderIdStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/orders/${queryArg.orderId}/status`,
          method: 'PATCH',
          body: queryArg.body,
        }),
      }),
      getManageNews: build.query<GetManageNewsApiResponse, GetManageNewsApiArg>({
        query: () => ({ url: `/manage/news` }),
        providesTags: ['NEWS'],
      }),
      postManageNews: build.mutation<PostManageNewsApiResponse, PostManageNewsApiArg>({
        query: (queryArg) => ({ url: `/manage/news`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['NEWS'],
      }),
      patchManageNews: build.mutation<PatchManageNewsApiResponse, PatchManageNewsApiArg>({
        query: (queryArg) => ({ url: `/manage/news`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['NEWS'],
      }),
      getManageNewsByNewsId: build.query<
        GetManageNewsByNewsIdApiResponse,
        GetManageNewsByNewsIdApiArg
      >({
        query: (queryArg) => ({ url: `/manage/news/${queryArg.newsId}` }),
        providesTags: ['NEWS'],
      }),
      deleteManageNewsByNewsId: build.mutation<
        DeleteManageNewsByNewsIdApiResponse,
        DeleteManageNewsByNewsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/news/${queryArg.newsId}`,
          method: 'DELETE',
          body: queryArg.uuid,
        }),
        invalidatesTags: ['NEWS'],
      }),
      getManageBrandsToValidate: build.query<
        GetManageBrandsToValidateApiResponse,
        GetManageBrandsToValidateApiArg
      >({
        query: () => ({ url: `/manage/brands/to-validate` }),
        providesTags: ['BRANDS'],
      }),
      patchManageBrandsByBrandIdToValidate: build.mutation<
        PatchManageBrandsByBrandIdToValidateApiResponse,
        PatchManageBrandsByBrandIdToValidateApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/brands/${queryArg.brandId}/to-validate`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['BRANDS'],
      }),
      getManageBrandsGroupList: build.query<
        GetManageBrandsGroupListApiResponse,
        GetManageBrandsGroupListApiArg
      >({
        query: () => ({ url: `/manage/brands/group-list` }),
        providesTags: ['BRANDS'],
      }),
      getManageOrdersByOrderIdPaid: build.query<
        GetManageOrdersByOrderIdPaidApiResponse,
        GetManageOrdersByOrderIdPaidApiArg
      >({
        query: (queryArg) => ({ url: `/manage/orders/${queryArg.orderId}/paid` }),
        providesTags: ['ORDERS'],
      }),
      patchManageOrdersByOrderIdPaid: build.mutation<
        PatchManageOrdersByOrderIdPaidApiResponse,
        PatchManageOrdersByOrderIdPaidApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/orders/${queryArg.orderId}/paid`,
          method: 'PATCH',
          body: queryArg.body,
        }),
      }),
      getManageUsersLeaderboard: build.query<
        GetManageUsersLeaderboardApiResponse,
        GetManageUsersLeaderboardApiArg
      >({
        query: () => ({ url: `/manage/users/leaderboard` }),
      }),
      getManageCampaignsByCampaignIdValidate: build.query<
        GetManageCampaignsByCampaignIdValidateApiResponse,
        GetManageCampaignsByCampaignIdValidateApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/campaigns/${queryArg.campaignId}/validate`,
          body: queryArg.body,
        }),
        providesTags: ['CAMPAIGNS'],
      }),
      patchManageCampaignsByCampaignIdValidate: build.mutation<
        PatchManageCampaignsByCampaignIdValidateApiResponse,
        PatchManageCampaignsByCampaignIdValidateApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/campaigns/${queryArg.campaignId}/validate`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['CAMPAIGNS'],
      }),
      getManageUsersByUserIdIgReels: build.query<
        GetManageUsersByUserIdIgReelsApiResponse,
        GetManageUsersByUserIdIgReelsApiArg
      >({
        query: (queryArg) => ({
          url: `/manage/users/${queryArg.userId}/ig-reels`,
          params: { 'get-fresh-data': queryArg['get-fresh-data'], cursor: queryArg.cursor },
        }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as coreApi };
export type LoginApiResponse = /** status 200 OK */ {
  user?: {
    id?: string;
    role?: 'admin' | 'brand' | 'influencer';
  };
  token?: string;
  refreshToken?: string;
  userLevel?: string;
};
export type LoginApiArg = {
  /** A JSON Object containing user credential */
  body: {
    /** Email */
    email?: string;
    /** password */
    password?: string;
  };
};
export type LogoutApiResponse = /** status 204 No Content */ {};
export type LogoutApiArg = void;
export type PostManageGoogleLoginApiResponse = /** status 200 OK */ {
  user?: {
    id?: string;
    role?: string;
  };
  token?: string;
  refreshToken?: string;
};
export type PostManageGoogleLoginApiArg = {
  body: {
    clientId?: string;
    credential?: string;
    accessToken?: string;
    expiresIn?: number;
    lang?: Lang;
  };
};
export type PostManageFacebookLoginApiResponse = /** status 200 OK */ {
  user?: {
    id?: string;
    role?: string;
  };
  token?: string;
  refreshToken?: string;
};
export type PostManageFacebookLoginApiArg = {
  /** a JSON object containing user credentials */
  body: {
    userID?: string;
    accessToken?: string;
    data_access_expiration_time?: number;
    graphDomain?: string;
    signedRequest?: string;
    lang?: Lang;
  };
};
export type PostManageForgotPasswordApiResponse = unknown;
export type PostManageForgotPasswordApiArg = {
  /** User's email */
  body: {
    email?: string;
  };
};
export type GetUsersApiResponse = /** status 200 OK */ {
  totalOfRecords: number;
  currentOffset: number;
  limit: number;
  userItemsList: UserItemForUserList[];
};
export type GetUsersApiArg = {
  /** current campaign id */
  campaignId?: string;
  /** Limit on the number of user obtained */
  limit?: string;
  /** Current offset fo user obtained  */
  offset?: string;
  /** A part of the user name  */
  search?: string;
  /** user status : 'registered' | 'incomplete' | 'refused' | 'active' | 'suspended' */
  filterStatus?: string;
  /** order : "asc" | "desc" */
  order?: string;
  /** orderBy: 'fullName' | 'instagramFollowers' | 'youtubeFollowers' | 'tiktokFollowers' | 'blogVisites' | 'createdAt' | 'ratingStars' | 'status' */
  orderBy?: string;
  /** current club id */
  excludeClub?: string;
};
export type PostUsersApiResponse = /** status 200 OK */ {
  /** created user id */
  id?: string;
};
export type PostUsersApiArg = {
  body: {
    general?: {
      avatarUrl?: string;
      status?: UserStatus;
      gender?: 'male' | 'female' | 'other';
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      interests?: Uuid[];
      tags?: TagList;
      clubs?: ClubNameList;
      notes?: string;
      phoneActivated?: boolean;
      mailActivated?: boolean;
      birthdate?: string;
    };
  };
};
export type PatchManageUsersApiResponse = unknown;
export type PatchManageUsersApiArg = {
  body: {
    ids?: Uuid[];
    method?: 'delete';
  };
};
export type GetManageUsersToValidateApiResponse = /** status 200 OK */ UserItemForUserList[];
export type GetManageUsersToValidateApiArg = void;
export type GetGeneralStatisticsApiResponse = /** status 200 OK */ {
  requestsToValidate?: number;
  runningCampaigns?: number;
  newMessages?: number;
  unreadNotifications?: number;
};
export type GetGeneralStatisticsApiArg = void;
export type GetUserStatisticsApiResponse = /** status 200 OK */ {
  usersToVerify?: number;
  requestsToValidate?: number;
  itemsToShip?: number;
  content?: {
    nbToValidate?: number;
    nbPosted?: number;
    postedPercent?: number;
    nbPending?: number;
    pendingPercent?: number;
  };
  totalView?: number;
  userActive?: ChartDataForStatistics;
  userSignup?: ChartDataForStatistics;
};
export type GetUserStatisticsApiArg = void;
export type GetManageStatisticsPlatformUsersDashboardApiResponse = /** status 200 OK */ {
  title?: string;
  data?: {
    name?: string;
    data?: number[];
  }[];
  labels?: string[];
}[];
export type GetManageStatisticsPlatformUsersDashboardApiArg = void;
export type GetSocialNetworksStatisticsApiResponse = /** status 200 OK */ ChartDataForStatistics[];
export type GetSocialNetworksStatisticsApiArg = void;
export type GetCampaignsApiResponse = /** status 200 OK */ CampaignItemForListOfCampaigns[];
export type GetCampaignsApiArg = void;
export type CreateCampaignApiResponse = /** status 200 OK */ {
  /** created campaign id */
  id?: string;
};
export type CreateCampaignApiArg = {
  campaignItemForCreation: CampaignItemForCreateOrUpdateCampaign;
};
export type UpdateOneCampaignApiResponse = unknown;
export type UpdateOneCampaignApiArg = {
  /** ID of campaign to update */
  campaignId: string;
  campaignItemForUpdate: CampaignItemForCreateOrUpdateCampaign2;
};
export type DeleteManageCampaignsByCampaignIdApiResponse = unknown;
export type DeleteManageCampaignsByCampaignIdApiArg = {
  /** ID of campaign to update */
  campaignId: string;
};
export type GetManageCampaignsByCampaignIdApiResponse =
  /** status 201 Created */ CampaignItemForSingleCampaignInAdminUi;
export type GetManageCampaignsByCampaignIdApiArg = {
  /** ID of campaign to update */
  campaignId: string;
};
export type UpdateOneCampaignStatusApiResponse = /** status 204 No Content */ {};
export type UpdateOneCampaignStatusApiArg = {
  /** ID of campaign to update */
  campaignId: string;
  body: {
    status?: CampaignStatus;
  };
};
export type GetManageStatisticsSocialNetworksFilterByTargetingApiResponse = /** status 200 OK */ {
  influencers?: ChartDataForStatisticsOfInfluencers;
  followers?: ChartDataForStatisticsFollowers;
  views?: ChartDataForStatisticsViews;
  engagements?: ChartDataForStatisticsEngagements;
};
export type GetManageStatisticsSocialNetworksFilterByTargetingApiArg = {
  /** Gender */
  gender?: ('male' | 'female' | 'other')[];
  /** Campaigns included */
  campaignsIncluded?: string[];
  /** Campaigns excluded */
  campaignsExcluded?: string[];
  /** Engagement Rate */
  engagementRate?: number;
  /** Rating */
  rating?: number;
  /** Interests */
  interests?: string[];
  /** Ages */
  ages?: string[];
  /** Instagram minimum followers */
  instagramMinFollowers?: number;
  /** Instagram maximum followers */
  instagramMaxFollowers?: number;
  /** Youtube minimum followers */
  youtubeMinFollowers?: number;
  /** Youtube maximum followers */
  youtubeMaxFollowers?: number;
  /** TikTok minimum followers */
  tikTokMinFollowers?: number;
  /** TikTok maximum followers */
  tikTokMaxFollowers?: number;
  /** Blog minimum followers */
  blogMinFollowers?: number;
  /** Blog maximum followers */
  blogMaxFollowers?: number;
  /** Countries included */
  countryIncluded?: string[];
  /** Countries excluded */
  countryExcluded?: string[];
  /** Regions included */
  regionsIncluded?: string[];
  /** Regions excluded */
  regionsExcluded?: string[];
  /** Cities included */
  cityIncluded?: string[];
  /** Cities excluded */
  cityExcluded?: string[];
  /** Clubs excluded */
  clubsExcluded?: string[];
  /** Clubs included */
  clubsIncluded?: string[];
  /** Users included */
  usersIncluded?: string[];
  /** Users excluded */
  usersExcluded?: string[];
};
export type PostManageStatisticsSocialNetworksFilterByTargetingApiResponse = /** status 200 OK */ {
  influencers?: ChartDataForStatisticsOfInfluencers;
  followers?: ChartDataForStatisticsFollowers;
  views?: ChartDataForStatisticsViews;
  engagements?: ChartDataForStatisticsEngagements;
};
export type PostManageStatisticsSocialNetworksFilterByTargetingApiArg = {
  /** optional - only for campaign edit not on creation */
  campaignId?: string;
  targetingBody: TargetingBody;
};
export type GetIncludedUsersListByFilterApiResponse = /** status 200 OK */ UserItemForUserList[];
export type GetIncludedUsersListByFilterApiArg = {
  /** Users included */
  includedUsersList?: boolean;
  /** Gender */
  gender?: ('male' | 'female' | 'other')[];
  /** Campaigns included */
  campaignsIncluded?: string[];
  /** Campaigns excluded */
  campaignsExcluded?: string[];
  /** Engagement Rate */
  engagementRate?: number;
  /** Rating */
  rating?: number;
  /** Interests */
  interests?: string[];
  /** Ages */
  ages?: string[];
  /** Instagram minimum followers */
  instagramMinFollowers?: number;
  /** Instagram maximum followers */
  instagramMaxFollowers?: number;
  /** Youtube maximum followers */
  youtubeMaxFollowers?: number;
  /** TikTok minimum followers */
  tikTokMinFollowers?: number;
  /** TikTok maximum followers */
  tikTokMaxFollowers?: number;
  /** Blog minimum followers */
  blogMinFollowers?: number;
  /** Blog maximum followers */
  blogMaxFollowers?: number;
  /** Countries included */
  countryIncluded?: string[];
  /** Countries excluded */
  countryExcluded?: string[];
  /** Regions included */
  regionsIncluded?: string[];
  /** Regions excluded */
  regionsExcluded?: string[];
  /** Cities included */
  cityIncluded?: string[];
  /** Cities excluded */
  cityExcluded?: string[];
  /** Users included */
  usersIncluded?: string[];
  /** Users excluded */
  usersExcluded?: string[];
};
export type GetManageCampaignsByCampaignIdTargetingApiResponse = /** status 200 OK */ TargetingBody;
export type GetManageCampaignsByCampaignIdTargetingApiArg = {
  /** campaignId */
  pathCampaignId: string;
  /** ID of campaign to update */
  _pathCampaignId: string;
};
export type PostManageCampaignsByCampaignIdTargetingApiResponse = unknown;
export type PostManageCampaignsByCampaignIdTargetingApiArg = {
  /** campaignId */
  pathCampaignId: string;
  /** ID of campaign to update */
  _pathCampaignId: string;
  /** A JSON Object containing targeting informations */
  targetingBody: TargetingBody;
};
export type UpdateFilterInformationForTargetingApiResponse = unknown;
export type UpdateFilterInformationForTargetingApiArg = {
  /** campaignId */
  pathCampaignId: string;
  /** ID of campaign to update */
  _pathCampaignId: string;
  /** A JSON Object containing targeting informations */
  targetingBody: TargetingBody;
};
export type GetManageUsersByUserIdOrdersStatisticsAndOrderIdApiResponse =
  /** status 200 OK */ UserItemForManageCampaignPage;
export type GetManageUsersByUserIdOrdersStatisticsAndOrderIdApiArg = {
  /** user id */
  userId: string;
  /** order id */
  orderId: string;
};
export type GetOrdersStatusApiResponse = /** status 200 OK */ {
  abandoned?: number;
  published?: number;
  readyToShip?: number;
  received?: number;
  shipped?: number;
  timedOut?: number;
  validated?: number;
  pending?: number;
  rejected?: number;
};
export type GetOrdersStatusApiArg = {
  /** Id of the brand */
  brandId?: string;
  /** id of the campaign */
  campaignId?: string;
};
export type GetOrdersApiResponse = /** status 200 OK */ {
  pagination?: {
    offset?: number;
    limit?: number;
    totalItems?: number;
  };
  data?: OrderItemForManageCampaign[];
};
export type GetOrdersApiArg = {
  /** offset */
  offset?: string;
  /** key word for search */
  search?: string;
  /** use to fetch archived orders */
  archived?: boolean;
  /** limit */
  limit?: string;
  /** orders status */
  status?: string;
  /** Id of the brand */
  brandId?: string;
  /** id of the campaign */
  campaignId?: string;
};
export type PostManageOrdersApiResponse = unknown;
export type PostManageOrdersApiArg = {
  body: {
    userId?: Uuid;
    campaignId?: Uuid;
    address?: AddressItemForOrders;
    motivation?: string;
  };
};
export type UpdateOrderApiResponse = /** status 204 No Content */ {};
export type UpdateOrderApiArg = {
  /** order id */
  pathOrderId: string;
  /** ID of order to update */
  _pathOrderId: Uuid;
  body: {
    logisticsInformation?: {
      providers?: string;
      number?: string;
      link?: string;
    };
    address?: AddressItem;
  };
};
export type DeleteManageOrdersByOrderIdApiResponse = unknown;
export type DeleteManageOrdersByOrderIdApiArg = {
  /** order id */
  orderId: string;
};
export type PatchManageMediasByMediaIdApiResponse = /** status 204 No Content */ {};
export type PatchManageMediasByMediaIdApiArg = {
  /** media id */
  mediaId: string;
  body: {
    status?: string;
    mediaType?:
      | 'instagramMedia'
      | 'instagramStory'
      | 'instagramReel'
      | 'youtube'
      | 'blog'
      | 'tiktok';
  };
};
export type GetManageCampaignsByCampaignIdReportingApiResponse = /** status 200 OK */ {
  campaignName?: string;
  brandName?: string;
  influencers?: ChartDataForStatisticsOfInfluencers;
  followers?: ChartDataForStatisticsFollowers;
  views?: ChartDataForStatisticsViews2;
  engagements?: ChartDataForStatisticsEngagements;
  engagementRate?: ChartDataForStatisticsOfEngagementsRate;
  contents?: ChartDataForStatisticsOfContent;
  EMV?: number;
  totalRequests?: number;
  postAwaiting?: number;
  daysLeft?: number;
  contentsObj?: MediaContentItemForReporting[];
};
export type GetManageCampaignsByCampaignIdReportingApiArg = {
  /** campaign id */
  campaignId: string;
};
export type GetManageUsersByUserIdApiResponse = /** status 200 OK */ UserItemForAdminRole;
export type GetManageUsersByUserIdApiArg = {
  /** user Id */
  userId: string;
};
export type GetManageUsersUserlistApiResponse = /** status 200 OK */ UserItemForUserList[];
export type GetManageUsersUserlistApiArg = {
  users?: any;
};
export type PatchManageUsersByUserIdGeneralApiResponse = /** status 204 No Content */ {};
export type PatchManageUsersByUserIdGeneralApiArg = {
  /** user Id */
  userId: string;
  body: {
    general?: {
      avatarUrl?: string;
      status?: UserStatus;
      gender?: 'male' | 'female' | 'other';
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      interests?: Uuid[];
      tags?: TagList;
      clubs?: Uuid[];
      notes?: string;
      phoneActivated?: boolean;
      mailActivated?: boolean;
      birthdate?: string;
    };
  };
};
export type PatchManageUsersByUserIdNotificationsApiResponse = /** status 204 No Content */ {};
export type PatchManageUsersByUserIdNotificationsApiArg = {
  /** user Id */
  userId: string;
  body: {
    notifications?: NotificationListForUser;
  };
};
export type PatchManageUsersByUserIdSocialNetworkLinksApiResponse = /** status 204 No Content */ {};
export type PatchManageUsersByUserIdSocialNetworkLinksApiArg = {
  /** user Id */
  userId: string;
  body: {
    socialNetworkLinks?: SocialNetworkItem[];
  };
};
export type PatchManageUsersByUserIdPasswordApiResponse = /** status 204 No Content */ {};
export type PatchManageUsersByUserIdPasswordApiArg = {
  /** user Id */
  userId: string;
  body: {
    newPassword: string;
    sendToUser: boolean;
  };
};
export type PatchManageUsersByUserIdStatusApiResponse = /** status 204 No Content */ {};
export type PatchManageUsersByUserIdStatusApiArg = {
  /** user Id */
  userId: string;
  body: {
    status?: UserStatus;
  };
};
export type PatchManageUsersByUserIdLangApiResponse = /** status 204 No Content */ {};
export type PatchManageUsersByUserIdLangApiArg = {
  /** user Id */
  userId: string;
  body: {
    lang?: Lang;
  };
};
export type PatchManageUsersByUserIdEmailApiResponse = /** status 204 No Content */ {};
export type PatchManageUsersByUserIdEmailApiArg = {
  /** user Id */
  userId: string;
  body: {
    email?: string;
  };
};
export type CreateNewAddressForOneUserApiResponse =
  /** status 201 Get addresses data */ AddressItemPost[];
export type CreateNewAddressForOneUserApiArg = {
  /** user Id */
  userId: string;
  /** A JSON Object containing order status information */
  addressItemPost: AddressItemPost;
};
export type UpdateOneUserAddressApiResponse = /** status 200 OK */ AddressItem[];
export type UpdateOneUserAddressApiArg = {
  /** user Id */
  userId: string;
  /** address Id */
  addressId: string;
  /** A JSON Object containing order status information */
  addressItem: AddressItem;
};
export type DeleteOneUserAddressApiResponse = /** status 204 No Content */ {};
export type DeleteOneUserAddressApiArg = {
  /** user Id */
  userId: string;
  /** address Id */
  addressId: string;
};
export type GetUserRatingsApiResponse = /** status 200 OK */ {
  ratingStatistics?: RatingStatistics;
  ratingList?: RatingItem[];
};
export type GetUserRatingsApiArg = {
  /** user Id */
  userId: string;
};
export type CreateRatingApiResponse = /** status 201 Get rating statistic data */ RatingStatistics;
export type CreateRatingApiArg = {
  /** user Id */
  userId: string;
  /** A JSON Object containing a rating Item for a user on a campaign */
  body: {
    ratingStars?: number;
    comments?: string;
    brandId?: Uuid;
    campaignId?: Uuid;
  };
};
export type GetbrandsWithCampaignsForAddUserRatingsApiResponse = /** status 200 OK */ {
  /** brand id */
  id?: string;
  /** brand name */
  name?: string;
  /** List of camapigns for this brand */
  campaigns?: {
    /** campaign id */
    id?: string;
    /** campaign name */
    name?: {
      text?: string;
      lang?: Lang;
    }[];
  }[];
}[];
export type GetbrandsWithCampaignsForAddUserRatingsApiArg = {
  /** user Id */
  userId: string;
};
export type GetManageUsersByUserIdRatingsAndRatingIdApiResponse =
  /** status 200 OK */ RatingItem | /** status 201 Created */ void;
export type GetManageUsersByUserIdRatingsAndRatingIdApiArg = {
  /** user id */
  userId: string;
  /** rating id */
  ratingId: string;
};
export type PatchManageUsersByUserIdRatingsAndRatingIdApiResponse = unknown;
export type PatchManageUsersByUserIdRatingsAndRatingIdApiArg = {
  /** user id */
  userId: string;
  /** rating id */
  ratingId: string;
  body: {
    ratingStars?: number;
    comments?: string;
  };
};
export type DeleteManageUsersByUserIdRatingsAndRatingIdApiResponse = unknown;
export type DeleteManageUsersByUserIdRatingsAndRatingIdApiArg = {
  /** user id */
  userId: string;
  /** rating id */
  ratingId: string;
};
export type GetBrandsApiResponse = /** status 200 OK */ BrandItemInBrandList2[];
export type GetBrandsApiArg = void;
export type CreateBrandsApiResponse = unknown;
export type CreateBrandsApiArg = {
  brandPostPatchItem: BrandPostPatchItem;
};
export type GetManageBrandsBrandsListApiResponse = /** status 200 OK */ BrandItemInBrandList[];
export type GetManageBrandsBrandsListApiArg = void;
export type GetOneBrandApiResponse = /** status 200 OK */ BrandItemWithAllOfDetails;
export type GetOneBrandApiArg = {
  pathBrandId: string;
  /** ID of brand */
  _pathBrandId: string;
};
export type UpdateBrandApiResponse = /** status 204 No Content */ {};
export type UpdateBrandApiArg = {
  pathBrandId: string;
  /** ID of campaign to update */
  _pathBrandId: string;
  brandPostPatchItem: BrandPostPatchItem;
};
export type DeleteManageBrandsByBrandIdApiResponse = unknown;
export type DeleteManageBrandsByBrandIdApiArg = {
  brandId: string;
};
export type PatchManageBrandsByBrandIdNotificationsApiResponse = unknown;
export type PatchManageBrandsByBrandIdNotificationsApiArg = {
  /** brand id */
  brandId: string;
  body: {
    notifications?: NotificationsForBrand;
  };
};
export type PatchManageBrandsByBrandIdSocialNetworkLinksApiResponse = unknown;
export type PatchManageBrandsByBrandIdSocialNetworkLinksApiArg = {
  /** brand id */
  brandId: string;
  body: {
    socialNetworkLinks?: SocialNetworkItem[];
  };
};
export type UpdateOneBrandPasswordApiResponse = /** status 204 No Content */ {};
export type UpdateOneBrandPasswordApiArg = {
  pathBrandId: string;
  /** ID of brand to update */
  _pathBrandId: Uuid;
  /** if need to send a mail for brand */
  'send-to-brand'?: boolean;
  /** A JSON Object containing a new password to update */
  body: {
    password?: string;
  };
};
export type GetClubsApiResponse = /** status 200 OK */ ClubItem[];
export type GetClubsApiArg = void;
export type PostClubsApiResponse = unknown;
export type PostClubsApiArg = {
  clubItemForPost: ClubItemForPost;
};
export type GetLanguagesApiResponse = /** status 200 OK */ {
  id?: string;
  languageCode?: string;
  languageName?: string;
}[];
export type GetLanguagesApiArg = void;
export type PostLanguagesApiResponse = unknown;
export type PostLanguagesApiArg = {
  clubItemForPost: ClubItemForPost;
};
export type PatchManageClubsByClubIdApiResponse = unknown;
export type PatchManageClubsByClubIdApiArg = {
  /** Club id */
  clubId: string;
  clubItemForPost: ClubItemForPost;
};
export type GetManageClubsByClubIdApiResponse = /** status 200 OK */ ClubItem;
export type GetManageClubsByClubIdApiArg = {
  /** Club id */
  clubId: string;
};
export type DeleteManageClubsByClubIdApiResponse = unknown;
export type DeleteManageClubsByClubIdApiArg = {
  /** Club id */
  clubId: string;
};
export type GetManageClubsByClubIdStatisticsApiResponse = /** status 200 OK */ {
  nbInfluencers?: ChartDataForStatisticsOfInfluencers;
  nbFollowers?: ChartDataForStatisticsFollowers;
  nbViews?: ChartDataForStatisticsViews;
  averageEngagements?: ChartDataForStatisticsEngagements;
};
export type GetManageClubsByClubIdStatisticsApiArg = {
  /** Club id */
  clubId: string;
};
export type GetManageClubsByClubIdUsersApiResponse = /** status 200 OK */ UserItemForUserList[];
export type GetManageClubsByClubIdUsersApiArg = {
  /** Club id */
  clubId: string;
};
export type PatchManageClubsByClubIdUsersApiResponse = unknown;
export type PatchManageClubsByClubIdUsersApiArg = {
  /** Club id */
  clubId: string;
  body: {
    methods?: 'add' | 'remove';
    ids?: Uuid[];
  };
};
export type GetManageChatConversationsApiResponse = /** status 200 OK */ {
  pagination?: {
    limit?: number;
    skip?: number;
    cursor?: string;
  };
  data?: ConversationItemForAdminChat[];
};
export type GetManageChatConversationsApiArg = {
  /** Search by keyword */
  search?: string;
  /** Limit on the number of conversations obtained */
  limit?: string;
  /** The last conversation id in the conversation list */
  cursor?: string;
};
export type DeleteManageChatConversationsApiResponse = unknown;
export type DeleteManageChatConversationsApiArg = {
  body: {
    messageId?: string;
    socketId?: string;
  };
};
export type PostManageChatNewConversationApiResponse = /** status 200 OK */ {
  /** conversation id */
  conversationId?: string;
};
export type PostManageChatNewConversationApiArg = {
  /** Order id for create a new conversation */
  body: {
    orderId?: string;
  };
};
export type GetChatUsersByUserIdConversationsAndConversationIdMessagesApiResponse =
  /** status 200 OK */ MessageToSendItemForChat[];
export type GetChatUsersByUserIdConversationsAndConversationIdMessagesApiArg = {
  /** Conversation id */
  conversationId: string;
  /** User id */
  userId: string;
  /** Limit on the number of conversations obtained */
  limit?: number;
  /** The last message id in the message list */
  cursor?: string;
};
export type PostChatUsersByUserIdConversationsAndConversationIdMessagesApiResponse =
  /** status 201 Created */ MessageToSendItemForChat2;
export type PostChatUsersByUserIdConversationsAndConversationIdMessagesApiArg = {
  /** Conversation id */
  conversationId: string;
  /** User id */
  userId: string;
  body: {
    message?: string;
    senderId?: string;
    attachments?: {
      name?: string;
      contentType?: ContentTypeForChatMessage;
      mediaPath?: string;
      createdAt?: string;
    }[];
  };
};
export type GetManageChatConversationsByConversationIdMessagesApiResponse =
  /** status 200 OK */ MessageToSendItemForChat[];
export type GetManageChatConversationsByConversationIdMessagesApiArg = {
  /** Conversation id */
  conversationId: string;
  /** Limit on the number of conversations obtained */
  limit?: number;
  /** The last message id in the message list */
  cursor?: string;
};
export type PostManageChatConversationsByConversationIdMessagesApiResponse = unknown;
export type PostManageChatConversationsByConversationIdMessagesApiArg = {
  /** Conversation id */
  conversationId: string;
  body: {
    message?: string;
    senderId: string;
    socketId?: string;
    attachments?: {
      name?: string;
      contentType?: ContentTypeForChatMessage;
      mediaPath?: string;
      createdAt?: string;
    }[];
  };
};
export type PostManageChatConversationsByConversationIdMarkAsReadApiResponse = unknown;
export type PostManageChatConversationsByConversationIdMarkAsReadApiArg = {
  /** conversation id  */
  conversationId: string;
};
export type GetManageInterestsApiResponse = /** status 200 OK */ {
  id?: Uuid;
  name?: string;
}[];
export type GetManageInterestsApiArg = void;
export type PostManageInterestsApiResponse = /** status 200 OK */ {
  name?: string;
};
export type PostManageInterestsApiArg = void;
export type GetManageNotificationsApiResponse = /** status 200 OK */ {
  id?: Uuid;
  title?: string;
  description?: string;
  avatar?: string;
  type?: string;
  createdAt?: string;
  readAt?: string;
}[];
export type GetManageNotificationsApiArg = {
  /** Limit on the number of notifications obtained */
  limit?: string;
  /** The last notification id in the notifications list */
  cursor?: string;
};
export type PutManageNotificationsReadApiResponse = unknown;
export type PutManageNotificationsReadApiArg = {
  body: {
    notificationIds?: string[];
  };
};
export type GetManageIndustriesApiResponse = /** status 200 OK */ {
  id?: Uuid;
  name?: string;
}[];
export type GetManageIndustriesApiArg = void;
export type PostManageIndustriesApiResponse = /** status 200 OK */ {
  name?: string;
};
export type PostManageIndustriesApiArg = void;
export type GetManageTagsApiResponse = /** status 200 OK */ TagList;
export type GetManageTagsApiArg = void;
export type GetManageClubsClubsListApiResponse = /** status 200 OK */ {
  id?: string;
  name?: string;
}[];
export type GetManageClubsClubsListApiArg = void;
export type GetManageChatPossibleConversationsApiResponse = /** status 200 OK */ {
  /** order id */
  id?: string;
  status?: OrderStatus;
  /** campaign information in the order */
  campaign?: {
    id?: string;
    featuredImage?: string;
    name?: {
      text?: string;
      lang?: Lang;
    }[];
  };
  /** brand information in the order */
  brand?: {
    id?: string;
    name?: string;
    logoPath?: string;
  };
  /** user information in the order */
  user?: {
    /** user id */
    id?: string;
    /** user firstname */
    firstName?: string;
    /** user lastname */
    lastName?: string;
    /** user avatar */
    avatar?: string;
  };
}[];
export type GetManageChatPossibleConversationsApiArg = {
  /** Search by keyword, find order with brand name or campaign name */
  search?: string;
};
export type GetManageUsersByUserIdOrdersAndOrderIdMediasStatisticsApiResponse =
  /** status 200 OK */ {
    globalAccountInformation?: {
      contents?: number;
      impressions?: number;
      views?: number;
      likes?: number;
      /** Global EMV for all social media networks */
      emv?: number;
    };
    instagram?: InstagramStatisticForOneOrder;
    youtube?: YouTubeStatisticOfOneOrder;
    tiktok?: TikTokStatisticOfOneOrder;
    blog?: BlogStatistics;
  };
export type GetManageUsersByUserIdOrdersAndOrderIdMediasStatisticsApiArg = {
  /** user id */
  userId: string;
  /** order id */
  orderId: string;
};
export type GetManageUsersByUserIdIgMediasApiResponse =
  /** status 200 OK */ MediaContentItemForFeed[];
export type GetManageUsersByUserIdIgMediasApiArg = {
  /** User id */
  userId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: number;
  /** get data from instagram api */
  'get-fresh-data'?: boolean;
  /** The last common mediaId on the media list */
  cursor?: string;
};
export type GetManageUsersByUserIdIgStoriesApiResponse =
  /** status 200 OK */ MediaContentItemForFeed[];
export type GetManageUsersByUserIdIgStoriesApiArg = {
  /** user id */
  userId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
  /** get data from instagram api */
  'get-fresh-data'?: boolean;
  /** the last common mediaID on the stories list */
  cursor?: string;
};
export type GetManageUsersByUserIdYoutubeApiResponse =
  /** status 200 OK */ MediaContentItemForFeed[];
export type GetManageUsersByUserIdYoutubeApiArg = {
  /** user id */
  userId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
  /** get data from youtube api */
  'get-fresh-data'?: boolean;
};
export type GetManageUsersByUserIdYoutubeShortsApiResponse =
  /** status 200 OK */ MediaContentItemForFeed[];
export type GetManageUsersByUserIdYoutubeShortsApiArg = {
  /** user id */
  userId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
  /** get fresh data from youtube api */
  'get-fresh-data'?: boolean;
};
export type GetManageUsersByUserIdTiktokApiResponse =
  /** status 200 OK */ MediaContentItemForFeed[];
export type GetManageUsersByUserIdTiktokApiArg = {
  /** user id */
  userId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
  /** get the latest data from Tiktok api */
  'get-fresh-data'?: boolean;
};
export type PostManageUploadApiResponse = /** status 201 Upload successful */ string[];
export type PostManageUploadApiArg = {
  body: {
    destination?: string;
    file?: Blob[];
  };
};
export type PostManageRefreshTokenApiResponse = /** status 200 OK */ {
  token?: string;
  refreshToken?: string;
};
export type PostManageRefreshTokenApiArg = {
  body: {
    id?: Uuid;
    refreshToken?: string;
  };
};
export type PostManageResetPasswordApiResponse = unknown;
export type PostManageResetPasswordApiArg = {
  body: {
    newPassword?: string;
    token?: string;
  };
};
export type GetManageChatConversationsByConversationIdFilesApiResponse = /** status 200 OK */ {
  name?: string;
  type?: 'image' | 'video' | 'pdf' | 'word' | 'excel' | 'txt';
  createdAt?: string;
  mediaPath?: string;
}[];
export type GetManageChatConversationsByConversationIdFilesApiArg = {
  /** conversation id */
  conversationId: string;
};
export type PostManageChatUploadApiResponse = /** status 201 Upload successful */ {
  name?: string;
  contentType?: ContentTypeForChatMessage;
  createdAt?: string;
  mediaPath?: string;
}[];
export type PostManageChatUploadApiArg = {
  body: {
    file?: Blob[];
  };
};
export type GetManageUsersByUserIdGhostLoginApiResponse = /** status 200 OK */ {
  accessToken?: string;
};
export type GetManageUsersByUserIdGhostLoginApiArg = {
  /** user id */
  userId: string;
};
export type GetManageUsersByUserIdOrdersApiResponse =
  /** status 200 OK */ OrderItemForOrderListInUserUi[];
export type GetManageUsersByUserIdOrdersApiArg = {
  userId: string;
};
export type PostManageUsersRegistrationApiResponse = /** status 201 Created */ {
  user?: {
    id?: string;
    status?: string;
  };
  token?: string;
  refreshToken?: string;
};
export type PostManageUsersRegistrationApiArg = {
  body: {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    lang?: Lang;
  };
};
export type GetManageApiResponse = /** status 200 OK */ AdminItemForEdit;
export type GetManageApiArg = void;
export type PostManageChatAuthApiResponse = /** status 200 OK */ AuthItem;
export type PostManageChatAuthApiArg = {
  body: {
    userId?: Uuid;
  };
};
export type GetManageNotificationsAuthApiResponse = unknown;
export type GetManageNotificationsAuthApiArg = void;
export type PostManageNotificationsAuthApiResponse = /** status 200 OK */ AuthItem;
export type PostManageNotificationsAuthApiArg = {
  body: {
    userId?: Uuid;
  };
};
export type GetManageNotificationsPusherBeamsAuthApiResponse = /** status 200 OK */ {
  token?: string;
};
export type GetManageNotificationsPusherBeamsAuthApiArg = void;
export type GetManagePermissionsGroupListApiResponse = /** status 200 OK */ ItemForPermissionsLists;
export type GetManagePermissionsGroupListApiArg = void;
export type GetManagePermissionsBrandListApiResponse =
  /** status 200 OK */ ItemForBrandPermissionsLists;
export type GetManagePermissionsBrandListApiArg = void;
export type GetManagePermissionsCampaignListApiResponse =
  /** status 200 OK */ ItemForCampaignPermissionsLists;
export type GetManagePermissionsCampaignListApiArg = void;
export type PatchManagePermissionsAssignApiResponse = unknown;
export type PatchManagePermissionsAssignApiArg = {
  listOfPermissionsPatchedForUser: ListOfPermissionsPatchedForUser;
};
export type GetManagePermissionsApiResponse = /** status 200 OK */ ListOfPermissionsAssignedToUser;
export type GetManagePermissionsApiArg = void;
export type GetManagePermissionsByUserIdApiResponse =
  /** status 200 OK */ ListOfPermissionsAssignedToUser;
export type GetManagePermissionsByUserIdApiArg = {
  /** userId */
  userId: string;
};
export type PostManageOrdersExportAddressesByCampaignIdApiResponse = unknown;
export type PostManageOrdersExportAddressesByCampaignIdApiArg = {
  campaignId: string;
};
export type PostManageSignupApiResponse = /** status 200 OK */ {
  user?: {
    id?: string;
    status?: string;
  };
  token?: string;
  refreshToken?: string;
};
export type PostManageSignupApiArg = {
  body: {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    lang?: Lang;
  };
};
export type PostManageSignupCheckEmailApiResponse = unknown;
export type PostManageSignupCheckEmailApiArg = {
  body: {
    code?: string;
  };
};
export type PostManageSignupGeneralApiResponse = unknown;
export type PostManageSignupGeneralApiArg = {
  adminItemForPostRegistrationGeneral: AdminItemForPostRegistrationGeneral;
};
export type PostManageSignupResendEmailCodeApiResponse = unknown;
export type PostManageSignupResendEmailCodeApiArg = void;
export type PostManageSignupCheckPhoneNumberApiResponse = unknown;
export type PostManageSignupCheckPhoneNumberApiArg = {
  body: {
    code?: string;
  };
};
export type PostManageSignupResendPhoneCodeApiResponse = unknown;
export type PostManageSignupResendPhoneCodeApiArg = void;
export type GetManageAdminsApiResponse = /** status 200 OK */ {
  AdminItemList?: string;
};
export type GetManageAdminsApiArg = {
  /** Limit on the number of admin obtained */
  limit?: string;
  /** A part of the Admin name */
  search?: string;
  /** Admin status : 'registered' | 'incomplete' | 'refused' | 'active' | 'suspended' */
  filterStatus?: string;
  /** order : "asc" | "desc" */
  order?: string;
  /** orderBy: 'fullName' | 'instagramFollowers' | 'youtubeFollowers' | 'tiktokFollowers' | 'blogVisites' | 'createdAt' | 'ratingStars' | 'status' */
  orderBy?: string;
};
export type PatchManageSignupPhoneNumberApiResponse = unknown;
export type PatchManageSignupPhoneNumberApiArg = {
  body: {
    phoneNumber?: string;
  };
};
export type GetManageToValidateApiResponse = /** status 200 OK */ AdminItemForAdminList[];
export type GetManageToValidateApiArg = void;
export type PatchManageUsersByUserIdAdminStatusApiResponse = unknown;
export type PatchManageUsersByUserIdAdminStatusApiArg = {
  userId: string;
  body: {
    status?: UserStatus;
  };
};
export type PostManageConsentApiResponse = unknown;
export type PostManageConsentApiArg = {
  body: {
    consent?: boolean;
  };
};
export type PatchManageOnboardingApiResponse = unknown;
export type PatchManageOnboardingApiArg = {
  body: {
    onboardingStep?: string;
  };
};
export type GetManageCampaignsAdminsApiResponse = /** status 200 OK */ AdminItemForOwnership[];
export type GetManageCampaignsAdminsApiArg = void;
export type PatchManageOrdersByOrderIdStatusApiResponse = unknown;
export type PatchManageOrdersByOrderIdStatusApiArg = {
  /** order id */
  orderId: string;
  body: {
    status?: OrderStatus;
  };
};
export type GetManageNewsApiResponse = /** status 200 OK */ NewsItemForNewsList[];
export type GetManageNewsApiArg = void;
export type PostManageNewsApiResponse = /** status 200 OK */ NewsItem;
export type PostManageNewsApiArg = {
  body: {
    category?: 'alert' | 'update';
    title?: string;
    description?: string;
    status?: 'published' | 'unpublished';
  };
};
export type PatchManageNewsApiResponse = unknown;
export type PatchManageNewsApiArg = {
  body: {
    news?: NewsItem[];
  };
};
export type GetManageNewsByNewsIdApiResponse = /** status 200 OK */ NewsItemForNewsList;
export type GetManageNewsByNewsIdApiArg = {
  /** newsId */
  newsId: string;
};
export type DeleteManageNewsByNewsIdApiResponse = unknown;
export type DeleteManageNewsByNewsIdApiArg = {
  /** newsId */
  newsId: string;
  uuid: Uuid;
};
export type GetManageBrandsToValidateApiResponse = /** status 200 OK */ GroupForBrand[];
export type GetManageBrandsToValidateApiArg = void;
export type PatchManageBrandsByBrandIdToValidateApiResponse = unknown;
export type PatchManageBrandsByBrandIdToValidateApiArg = {
  /** Id of the brand to validate */
  brandId: string;
  /** type could be
    valid-group -> pass the existing groupId
    new-group -> pass null as groupId
    invalid-group -> pass null as groupId */
  body: {
    type?: string;
    groupId?: string | null;
  };
};
export type GetManageBrandsGroupListApiResponse = /** status 200 OK */ ItemForPermissionsLists;
export type GetManageBrandsGroupListApiArg = void;
export type GetManageOrdersByOrderIdPaidApiResponse = /** status 200 OK */ boolean;
export type GetManageOrdersByOrderIdPaidApiArg = {
  /** order Id */
  orderId: string;
};
export type PatchManageOrdersByOrderIdPaidApiResponse = /** status 200 OK */ boolean;
export type PatchManageOrdersByOrderIdPaidApiArg = {
  /** order Id */
  orderId: string;
  body: {
    paid?: boolean;
  };
};
export type GetManageUsersLeaderboardApiResponse = /** status 200 OK */ Leaderboard;
export type GetManageUsersLeaderboardApiArg = void;
export type GetManageCampaignsByCampaignIdValidateApiResponse = /** status 200 OK */ {
  Validated?: boolean;
};
export type GetManageCampaignsByCampaignIdValidateApiArg = {
  campaignId: string;
  body: string;
};
export type PatchManageCampaignsByCampaignIdValidateApiResponse = unknown;
export type PatchManageCampaignsByCampaignIdValidateApiArg = {
  campaignId: string;
  body: {
    validated?: boolean;
  };
};
export type GetManageUsersByUserIdIgReelsApiResponse =
  /** status 200 OK */ MediaContentItemForFeed[];
export type GetManageUsersByUserIdIgReelsApiArg = {
  userId: string;
  /** get data from instagram api */
  'get-fresh-data'?: boolean;
  /** the last common mediaID on the stories list */
  cursor?: string;
};
export type Lang = 'en' | 'fr' | 'cn';
export type Uuid = string;
export type UserSocialNetworkFollowersAndUrl = {
  instagram?: {
    url?: string;
    followers?: number;
  };
  youtube?: {
    url?: string;
    followers?: number;
  };
  tiktok?: {
    url?: string;
    followers?: number;
  };
  blog?: {
    url?: string;
    visites?: number;
  };
};
export type UserStatus =
  | 'registered'
  | 'incomplete'
  | 'refused'
  | 'active'
  | 'suspended'
  | 'revoked';
export type UserItemForUserList = {
  id: Uuid;
  fullName: string;
  avatarUrl: string;
  /** Number of unread conversation of that user
   */
  unreadConversationsCount?: number;
  /** Number of unread conversation of that user
   */
  onlineStatus?: 'offline' | 'online';
  socialNetworks?: UserSocialNetworkFollowersAndUrl;
  createdAt: string;
  ratingStars?: number;
  status: UserStatus;
  /** If api is called with campaignID, response will include the field to clarify if on the campaign there is an active order. */
  hasOpenOrder?: boolean;
  role?: string;
};
export type TagList = string[];
export type ClubNameList = string[];
export type ChartDataForStatistics = {
  title?: string;
  percent?: number;
  total?: number;
  chartData?: number[];
};
export type CampaignStatus = 'draft' | 'scheduled' | 'running' | 'finished';
export type Ownership = {
  id?: Uuid;
  name?: string;
};
export type CampaignItemForListOfCampaigns = {
  id?: Uuid;
  featuredImage?: string;
  stock?: number;
  total?: number;
  startDate?: string;
  endDate?: string;
  status?: CampaignStatus;
  name?: {
    text?: string;
    lang?: Lang;
  }[];
  brand?: {
    id?: Uuid;
    name?: string;
  };
  request?: number;
  socialNetworks?: string[];
  EMV?: number;
  messages?: number;
  owner?: Ownership;
};
export type ArrayOfImagePaths = string[];
export type ArrayOfStrings = string[];
export type CampaignItemForCreateOrUpdateCampaign = {
  ownerId?: Uuid;
  campaignTranslations?: {
    name?: string;
    description?: string;
    details?: string;
    requirements?: string;
    languageId?: Uuid;
    ctaText?: string;
    options?: {
      name?: string;
      content?: string[];
    }[];
  }[];
  descriptionImages?: ArrayOfImagePaths;
  inspirationImages?: ArrayOfImagePaths;
  bannerImage?: string | null;
  featuredImage?: string;
  socialNetworks?: {
    name?: string;
    details?: string;
  }[];
  stock?: number;
  price?: number;
  teaser?: boolean;
  startDate?: string;
  endDate?: string;
  hashtags?: ArrayOfStrings;
  mentions?: ArrayOfStrings;
  campaignLink?: string;
  youtubeLink?: string;
  brandId?: Uuid;
  paid?: boolean;
};
export type CampaignItemForCreateOrUpdateCampaign2 = {
  ownerId?: Uuid;
  campaignTranslations?: {
    name?: string;
    description?: string;
    details?: string;
    requirements?: string;
    languageId?: Uuid;
    ctaText?: string;
    options?: {
      name?: string;
      content?: ArrayOfStrings;
    }[];
  }[];
  descriptionImages?: ArrayOfImagePaths;
  inspirationImages?: ArrayOfImagePaths;
  bannerImage?: string | null;
  featuredImage?: string;
  socialNetworks?: {
    name?: string;
    details?: string;
  }[];
  stock?: number;
  price?: number;
  teaser?: boolean;
  startDate?: string;
  endDate?: string;
  hashtags?: ArrayOfStrings;
  mentions?: ArrayOfStrings;
  campaignLink?: string;
  youtubeLink?: string;
  brandId?: Uuid;
  status?: CampaignStatus;
  paid?: boolean;
};
export type CampaignItemForSingleCampaignInAdminUi = {
  id?: Uuid;
  descriptionImages?: ArrayOfImagePaths;
  inspirationImages?: ArrayOfImagePaths;
  bannerImage?: string | null;
  featuredImage?: string;
  socialNetworks?: {
    name?: string;
    details?: string;
  }[];
  stock?: number;
  price?: number;
  teaser?: boolean;
  startDate?: string;
  endDate?: string;
  hashtags?: ArrayOfStrings;
  mentions?: ArrayOfStrings;
  campaignLink?: string;
  youtubeLink?: string;
  status?: CampaignStatus;
  total?: number;
  campaignDescription?: {
    text?: string;
    lang?: Lang;
  }[];
  campaignDetails?: {
    text?: string;
    lang?: Lang;
  }[];
  name?: {
    text?: string;
    lang?: Lang;
  }[];
  requirements?: {
    text?: string;
    lang?: Lang;
  }[];
  ctaText?: {
    text?: string;
    lang?: Lang;
  }[];
  options?: {
    text?: {
      name?: string;
      content?: ArrayOfStrings;
    }[];
    lang?: Lang;
  }[];
  brand?: {
    name?: string;
    logoPath?: string;
    id?: Uuid;
  };
  owner?: Ownership;
  paid?: boolean;
  campaignValidation?: boolean;
};
export type ChartDataForStatisticsOfInfluencers = {
  chartData?: number[];
  labels?: string[];
};
export type ChartDataForStatisticsFollowers = {
  total?: number;
  instagram?: number;
  youtube?: number;
  tiktok?: number;
};
export type ChartDataForStatisticsViews = {
  total?: number;
  /** Sum of instagramPost, instagramVideo and instagramCarousel */
  instagramMedia?: number;
  youtube?: number;
  tiktok?: number;
  instagramStory?: number;
};
export type ChartDataForStatisticsEngagements = {
  total?: number;
  instagram?: number;
  youtube?: number;
  tiktok?: number;
};
export type OrderStatus =
  | 'pending'
  | 'rejected'
  | 'readyToShip'
  | 'shipped'
  | 'received'
  | 'published'
  | 'validated'
  | 'timedOut'
  | 'abandoned';
export type TargetingBody = {
  gender?: ('male' | 'female' | 'other')[];
  engagementRate?: number;
  ages?: string[];
  interests?: Uuid[];
  ratingStars?: number;
  instagramMinFollowers?: number;
  instagramMaxFollowers?: number;
  youtubeMinFollowers?: number;
  youtubeMaxFollowers?: number;
  tikTokMinFollowers?: number;
  tikTokMaxFollowers?: number;
  blogMinFollowers?: number;
  blogMaxFollowers?: number;
  campaignsExcluded?: {
    campaignId?: Uuid;
    ordersStatus?: OrderStatus;
  }[];
  campaignsIncluded?: {
    campaignId?: Uuid;
    ordersStatus?: OrderStatus;
  }[];
  clubsExcluded?: string[];
  clubsIncluded?: string[];
  countriesExcluded?: string[];
  countriesIncluded?: string[];
  regionsIncluded?: string[];
  regionsExcluded?: string[];
  citiesExcluded?: {
    label?: string;
    radiusAround?: number;
  }[];
  citiesIncluded?: {
    label?: string;
    radiusAround?: number;
  }[];
  usersExcluded?: Uuid[];
  usersIncluded?: Uuid[];
  status?: CampaignStatus;
};
export type Date = string;
export type ChatStatus = 'online' | 'offline';
export type SocialNetworkItem = {
  /** blog is for user item, website is for brand item */
  name?:
    | 'instagram'
    | 'tiktok'
    | 'youtube'
    | 'blog'
    | 'website'
    | 'facebook'
    | 'linkedin'
    | 'twitter';
  link?: string;
  accountName?: string;
  tokenStatus?: boolean;
  tokenExpiry?: number;
  reauthorize?: boolean;
};
export type AudienceCountrySchema = {
  [key: string]: number;
};
export type AudienceCitySchema = {
  [key: string]: number;
};
export type InstagramStatisticForUserSocialNetworkMedia = {
  /** engagement rate is all of interactions (likes, dislikes and comments) divided by followers of this account */
  engagementRate?: number;
  /** engagement rate is all of interactions (likes, dislikes and comments) divided by followers of this account */
  postsEngagementRate?: number;
  /** engagement rate is all of interactions (likes, dislikes and comments) divided by followers of this account */
  reelsEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) divided by reach of this account */
  tribzTrueEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) divided by reach of this account */
  postsTribzTrueEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) divided by reach of this account */
  reelsTribzTrueEngagementRate?: number;
  followers?: number;
  following?: number;
  reach30Day?: number;
  impressions30Day?: number;
  posts?: number;
  averagePostImpressions?: number;
  postImpressionRate?: number;
  averagePostReach?: number;
  postReachRate?: number;
  averagePostComments?: number;
  postsPerMonth?: number;
  averagePostLikes?: number;
  averageCarouselLikes?: number;
  averageStoryViews?: number;
  storyViewRate?: number;
  /** replies for story */
  averageStoryComments?: number;
  averageStoryClicks?: number;
  averageStoryLikes?: number;
  averageReelViews?: number;
  reelViewRate?: number;
  averageReelReach?: number;
  qualityScore?: number;
  averageReelComments?: number;
  reelReachRate?: number;
  averageReelLikes?: number;
  'averageReelLikes - copy'?: number;
  audienceCountry?: AudienceCountrySchema;
  audienceCity?: AudienceCitySchema;
  lastPost?: string;
  audienceDemographic?: {
    'F.13-17'?: number;
    'F.18-24'?: number;
    'F.25-34'?: number;
    'F.35-44'?: number;
    'F.45-54'?: number;
    'F.55-64'?: number;
    'F.65+'?: number;
    'M.13-17'?: number;
    'M.18-24'?: number;
    'M.25-34'?: number;
    'M.35-44'?: number;
    'M.45-54'?: number;
    'M.55-64'?: number;
    'M.65+'?: number;
    'U.13-17'?: number;
    'U.18-24'?: number;
    'U.25-34'?: number;
    'U.35-44'?: number;
    'U.45-54'?: number;
    'U.55-64'?: number;
    'U.65+'?: number;
  };
};
export type YouTubeStatisticForUserSocialNetworkMedia = {
  /** engagement rate is all of interactions (likes, dislikes and comments) of Youtube normal Video and Shorts divided by followers of this account */
  engagementRate?: number;
  /** YouTube Video engagement rate is all of interactions (likes, dislikes and comments) of YouTube normal Video divided by followers of this account */
  videoEngagementRate?: number;
  /** YouTube Shorts engagement rate is all of interactions (likes, dislikes and comments) of YouTube Shorts divided by followers of this account */
  shortsEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) of Youtube normal Video and Shorts divided by views or impressions  of this account */
  tribzTrueEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) of Youtube normal Video divided by views or impressions  of this account */
  videoTribzTrueEngagementRate?: number;
  /** YouTube Shorts TTER is all of interactions (likes, dislikes and comments) of YouTube Shorts divided by views or impressions  of this account */
  shortsTribzTrueEngagementRate?: number;
  averageDislikes?: number;
  shortsAverageDislikes?: number;
  averageLikes?: number;
  shortsAverageLikes?: number;
  averageViews?: number;
  shortsAverageViews?: number;
  viewRate?: number;
  shortsViewRate?: number;
  followers?: number;
  videos?: number;
  shortsVideos?: number;
  averageComments?: number;
  shortsAverageComments?: number;
  audienceCountry?: AudienceCountrySchema;
  audienceDemographic?: {
    'F.13-17'?: number;
    'F.18-24'?: number;
    'F.25-34'?: number;
    'F.35-44'?: number;
    'F.45-54'?: number;
    'F.55-64'?: number;
    'F.65+'?: number;
    'M.13-17'?: number;
    'M.18-24'?: number;
    'M.25-34'?: number;
    'M.35-44'?: number;
    'M.45-54'?: number;
    'M.55-64'?: number;
    'M.65+'?: number;
    'U.13-17'?: number;
    'U.18-24'?: number;
    'U.25-34'?: number;
    'U.35-44'?: number;
    'U.45-54'?: number;
    'U.55-64'?: number;
    'U.65+'?: number;
  };
};
export type TikTokStatisticForUserSocialNetworkMedia = {
  engagementRate?: number;
  tribzTrueEngagementRate?: number;
  totalLikes?: number;
  followers?: number;
  following?: number;
  totalViews?: number;
};
export type BlogStatistics = {
  link?: string;
  monthlyVisitors?: number;
  /** EMV for blog */
  emv?: number;
};
export type UserSocialNetworkStatisticsByMedia = {
  instagram?: InstagramStatisticForUserSocialNetworkMedia;
  youtube?: YouTubeStatisticForUserSocialNetworkMedia;
  tiktok?: TikTokStatisticForUserSocialNetworkMedia;
  blog?: BlogStatistics;
};
export type MediaTypeForOrder =
  | 'instagramPost'
  | 'instagramCarousel'
  | 'instagramVideo'
  | 'instagramStory'
  | 'instagramReel'
  | 'youtubeVideo'
  | 'youtubeShorts'
  | 'tiktokVideo'
  | 'blog';
export type MetricsSchema = {
  [key: string]: number;
};
export type MediaAttributesSchema = object;
export type MediaContentItemForOrder = {
  id?: Uuid;
  mediaType?: MediaTypeForOrder;
  metrics?: MetricsSchema;
  publishDate?: string;
  status?: 'published' | 'validated';
  nativeMediaId?: string;
  mediaPath?: string[];
  attributes?: MediaAttributesSchema;
  brandName?: string;
  campaign?: {
    id?: string;
    name?: {
      text?: string;
      lang?: Lang;
    }[];
  };
};
export type UserItemForManageCampaignPage = {
  biography?: string;
  city?: string;
  /** display for request notes */
  motivation?: string;
  /** display for campaign histroy */
  orderList?: {
    id?: Uuid;
    status?: OrderStatus;
    updatedAt?: Date;
    name?: {
      text?: string;
      lang?: Lang;
    }[];
    brandName?: string;
  }[];
  id?: Uuid;
  fullName?: string;
  avatarUrl?: string;
  gender?: 'male' | 'female' | 'other';
  /** Number of messages unread of the user */
  unreadMessagesCount?: number;
  onlineStatus?: ChatStatus;
  age?: number;
  /** display in the header card for social network logo */
  socialNetworkLinks?: SocialNetworkItem[];
  socialNetworksStatisticsByMedia?: UserSocialNetworkStatisticsByMedia;
  rating?: {
    ratingStars?: number;
    totalComments?: number;
  };
  /** display for history */
  historyStatistic?: {
    pending?: number;
    readyToShip?: number;
    rejected?: number;
    shipped?: number;
    received?: number;
    published?: number;
    validated?: number;
    abandoned?: number;
    timedOut?: number;
  };
  publicationsHistory?: MediaContentItemForOrder[];
};
export type AddressItemForOrders = {
  city?: string;
  title?: string;
  address?: string;
  country?: string;
  default?: boolean;
  zipCode?: string;
  lastName?: string;
  createdAt?: Date;
  firstName?: string;
  updatedAt?: Date;
  supplement?: string;
};
export type UserItemForOrderList = {
  id?: Uuid;
  avatarUrl?: string;
  fullName?: string;
  ratingStars?: number;
  socialNetworkStatistics?: {
    instagram?: {
      followers?: number;
      engagementRate?: number;
      likes?: number;
      qualityScore?: number;
    };
    youtube?: {
      followers?: number;
      engagementRate?: number;
      views?: number;
    };
    blog?: boolean;
  };
  onlineStatus?: ChatStatus;
};
export type BrandStatus = 'active' | 'on hold' | 'deactivated';
export type BrandItemInBrandList = {
  id?: Uuid;
  name?: string;
  logoPath?: string;
  group?: string;
  status?: BrandStatus;
};
export type CampaignItemForOrderList = {
  id?: string;
  stock?: number;
  total?: number;
  name?: {
    text?: string;
    lang?: Lang;
  }[];
  brand?: BrandItemInBrandList;
  request?: number;
};
export type OrderItemForManageCampaign = {
  id: Uuid;
  status: OrderStatus;
  paid?: boolean;
  address: AddressItemForOrders;
  motivation: string;
  logisticsInformation?: {
    number: string;
    providers: string;
    createdAt: Date;
    updatedAt?: Date;
    link?: string;
  };
  shippeddate?: Date;
  receiveddate?: Date;
  createdAt: Date;
  updatedAt?: Date;
  user: UserItemForOrderList;
  campaign: CampaignItemForOrderList;
  contents?: MediaContentItemForOrder[];
  /** This conversation id is optionnel */
  conversationId?: string;
  unreadMessagesCount?: number;
  options?: {
    key?: string;
    value?: string;
  }[];
};
export type AddressItem = {
  id?: Uuid;
  title?: string;
  default?: boolean;
  userId?: Uuid;
  address?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  supplement?: string;
  firstName?: string;
  lastName?: string;
  createdAt?: Date;
  updatedAt?: Date;
};
export type ChartDataForStatisticsViews2 = {
  total?: number;
  /** Sum of instagramPost, instagramVideo and instagramCarousel */
  instagramMedia?: number;
  youtube?: number;
  tiktok?: number;
  instagramStory?: number;
  instagramReel?: number;
};
export type ChartDataForStatisticsOfEngagementsRate = {
  global?: number;
  instagram?: {
    campaign?: number;
    general?: number;
  };
  youtube?: {
    campaign?: number;
    general?: number;
  };
  tiktok?: {
    campaign?: number;
    general?: number;
  };
};
export type ChartDataForStatisticsOfContent = {
  total?: number;
  /** Sum of instagramPost, instagramVideo and instagramCarousel */
  instagramMedia?: number;
  instagramStory?: number;
  instagramReel?: number;
  youtube?: number;
  tiktok?: number;
  blog?: number;
};
export type MediaContentItemForReporting = {
  id?: Uuid;
  mediaType?: MediaTypeForOrder;
  mediaPath?: string;
  publishDate?: string;
  metrics?: MetricsSchema;
  EMV?: number;
  status?: 'published' | 'validated';
  user?: {
    id?: Uuid;
    avatarUrl?: string;
    fullName?: string;
  };
  attributes?: MediaAttributesSchema;
};
export type NotificationListForUser = {
  userId?: Uuid;
  EmailMeWhenIGetAMessageFromTribz?: boolean;
  EmailMeWhenTheStatusOfMyOrderChanges?: boolean;
  NewsAndAnnouncements?: boolean;
  WeeklyProductUpdates?: boolean;
  WeeklyBlogDigest?: boolean;
};
export type RatingItem = {
  id?: Uuid;
  comments?: string;
  ratingStars?: number;
  userId?: Uuid;
  createdAt?: Date;
  updatedAt?: Date;
  campaign?: {
    id?: Uuid;
    name?: {
      text?: string;
      lang?: Lang;
    }[];
  };
  brand?: {
    id?: Uuid;
    name?: string;
  };
};
export type RatingStatistics = {
  averageStars?: number;
  totalComments?: number;
  totalByStars?: {
    '0'?: {
      totalComments?: number;
      rate?: number;
    };
    '1'?: {
      totalComments?: number;
      rate?: number;
    };
    '2'?: {
      totalComments?: number;
      rate?: number;
    };
    '3'?: {
      totalComments?: number;
      rate?: number;
    };
    '4'?: {
      totalComments?: number;
      rate?: number;
    };
    '5'?: {
      totalComments?: number;
      rate?: number;
    };
  };
};
export type UserItemForAdminRole = {
  id?: Uuid;
  avatarUrl?: string;
  status?: UserStatus;
  mailActivated?: boolean;
  phoneActivated?: boolean;
  credits?: number;
  usedCredits?: number;
  gender?: 'male' | 'female' | 'other';
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  birthdate?: string;
  /** Insterests is a array of interests id seleted  */
  interests?: Uuid[];
  tags?: TagList;
  clubs?: ClubNameList;
  notes?: string;
  addresses?: AddressItem[];
  socialNetworkLinks?: SocialNetworkItem[];
  notifications?: NotificationListForUser;
  ratings?: {
    ratingList?: RatingItem[];
    ratingStatistics?: RatingStatistics;
  };
  socialNetworksStatisticsByMedia?: UserSocialNetworkStatisticsByMedia;
  role?: 'admin' | 'influencer';
  /** if there are unread msg in conversations, this variable contains number of unread conversation from this user in the chat */
  unreadConversationsCount?: number;
  onlineStatus?: ChatStatus;
  lang?: Lang;
  signUpInfo?: {
    brand?: string;
    group?: string;
  };
};
export type AddressItemPost = {
  id?: Uuid;
  city: string;
  title: string;
  userid?: Uuid;
  address: string;
  country: string;
  default: boolean;
  zipCode: string;
  lastName?: string;
  createdAt?: Date;
  firstName?: string;
  updatedAt?: Date;
  supplement?: string;
};
export type BrandItemInBrandList2 = {
  id?: string;
  name?: string;
  logoPath?: string;
  group?: string;
  activeCampaigns?: number;
  totalCampaigns?: number;
  totalContents?: number;
  status?: BrandStatus;
};
export type BrandPostPatchItem = {
  logoPath?: string;
  status?: BrandStatus;
  mailActivated?: boolean;
  brandName?: string;
  group?: {
    name?: string;
    id?: Uuid;
  };
  gender?: 'male' | 'female' | 'other';
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  addresses?: {
    address?: string;
    country?: string;
    city?: string;
    zipCode?: string;
    supplement?: string;
  };
  industry?: Uuid[];
  notes?: string;
};
export type NotificationsForBrand = {
  EmailMeWhenMyCampaignStatusChanges?: boolean;
  EmailMeWhenIGetAChatMessage?: boolean;
  NewsAndAnnouncements?: boolean;
  WeeklyProductUpdates?: boolean;
};
export type BrandItemWithAllOfDetails = {
  id?: Uuid;
  logoPath?: string;
  status?: BrandStatus;
  mailActivated?: boolean;
  brandName?: string;
  brandGroupId?: Uuid;
  group?: string;
  gender?: 'male' | 'female' | 'other';
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  addresses?: {
    address?: string;
    country?: string;
    city?: string;
    zipCode?: string;
    supplement?: string;
  };
  industry?: Uuid[];
  notes?: string;
  notifications?: NotificationsForBrand;
  socialNetworkLinks?: SocialNetworkItem[];
};
export type ClubItem = {
  id?: Uuid;
  name?: string;
  description?: string;
  url?: string;
  status?: 'active' | 'pending';
  logoPath?: string;
  bannerPath?: string;
  clubTag?: string;
  landingPage?: boolean;
  nbInfluencers?: number;
};
export type ClubItemForPost = {
  id?: Uuid;
  name?: string;
  description?: string;
  url?: string;
  status?: 'active' | 'pending';
  logoPath?: string;
  bannerPath?: string;
  clubTag?: string;
  landingPage?: boolean;
};
export type ConversationItemForAdminChat = {
  /** conversation id */
  id?: string;
  /** user id */
  userId?: string;
  /** user avatar */
  avatar?: string;
  /** user full name */
  fullName?: string;
  /** user phone number */
  phoneNumber?: string;
  /** order id */
  orderId?: string;
  brandName?: string;
  campaignName?: {
    text?: string;
    lang?: string;
  }[];
  status?: ChatStatus;
  lastMsg?: string;
  lastMsgTime?: string;
  unreadMessagesCount?: number;
};
export type ContentTypeForChatMessage =
  | 'text'
  | 'video'
  | 'image'
  | 'pdf'
  | 'word'
  | 'excel'
  | 'txt';
export type MessageToSendItemForChat = {
  /** message id */
  id?: string;
  message?: string;
  contentTypes?: ContentTypeForChatMessage[];
  attachments?: string[];
  senderId?: string;
  createdAt?: string;
  isUnread?: boolean;
};
export type MessageToSendItemForChat2 = {
  conversationId?: Uuid;
  message?: string;
  contentTypes?: ContentTypeForChatMessage[];
  attachments?: Blob[];
  createdAt?: Date;
  senderId?: Uuid;
  socketId?: string;
};
export type InstagramStatisticForOneOrder = {
  /** engagement rate is all of interactions (likes, dislikes and comments) divided by followers of this account */
  engagementRate?: number;
  /** engagement rate is all of interactions (likes, dislikes and comments) divided by followers of this account */
  postsEngagementRate?: number;
  /** engagement rate is all of interactions (likes, dislikes and comments) divided by followers of this account */
  reelsEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) divided by reach of this account */
  tribzTrueEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) divided by reach of this account */
  postsTribzTrueEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) divided by reach of this account */
  reelsTribzTrueEngagementRate?: number;
  posts?: number;
  averagePostImpressions?: number;
  postImpressionRate?: number;
  averagePostReach?: number;
  postReachRate?: number;
  averagePostComments?: number;
  averagePostLikes?: number;
  averageCarouselLikes?: number;
  averageStoryViews?: number;
  storyViewRate?: number;
  /** replies for story */
  averageStoryComments?: number;
  averageStoryClicks?: number;
  averageStoryLikes?: number;
  averageReelViews?: number;
  reelViewRate?: number;
  averageReelReach?: number;
  averageReelComments?: number;
  reelReachRate?: number;
  averageReelLikes?: number;
  reach?: number;
  impression?: number;
  /** EMV for instagram */
  emv?: number;
};
export type YouTubeStatisticOfOneOrder = {
  /** engagement rate is all of interactions (likes, dislikes and comments) of Youtube normal Video and Shorts divided by followers of this account */
  engagementRate?: number;
  /** YouTube Video engagement rate is all of interactions (likes, dislikes and comments) of YouTube normal Video divided by followers of this account */
  videoEngagementRate?: number;
  /** YouTube Shorts engagement rate is all of interactions (likes, dislikes and comments) of YouTube Shorts divided by followers of this account */
  shortsEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) of Youtube normal Video and Shorts divided by views or impressions  of this account */
  tribzTrueEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) of Youtube normal Video divided by views or impressions  of this account */
  videoTribzTrueEngagementRate?: number;
  /** YouTube Shorts TTER is all of interactions (likes, dislikes and comments) of YouTube Shorts divided by views or impressions  of this account */
  shortsTribzTrueEngagementRate?: number;
  averageDislikes?: number;
  shortsAverageDislikes?: number;
  averageLikes?: number;
  shortsAverageLikes?: number;
  averageViews?: number;
  shortsAverageViews?: number;
  viewRate?: number;
  shortsViewRate?: number;
  videos?: number;
  shortsVideos?: number;
  averageComments?: number;
  shortsAverageComments?: number;
  totalViewsYoutube?: number;
  totalViewsYoutubeShorts?: number;
  /** EMV for youtube */
  emv?: number;
};
export type TikTokStatisticOfOneOrder = {
  engagementRate?: number;
  tribzTrueEngagementRate?: number;
  totalLikes?: number;
  totalViews?: number;
  /** EMV for tiktok */
  emv?: number;
};
export type MediaContentItemForFeed = {
  nativeMediaId?: string;
  mediaType?: MediaTypeForOrder;
  /** media_url (for instagram post and carousel) or thumbnail_url (for instagram video) */
  mediaPath?: string[];
  metrics?: MetricsSchema;
  attributes?: MediaAttributesSchema;
  publishDate?: string;
  /** permalink */
  link?: string;
};
export type OrderItemForOrderListInUserUi = {
  id: Uuid;
  status: OrderStatus;
  address: AddressItemPost;
  logisticsInformation?: {
    number: string;
    providers: string;
    createdAt: Date;
    updatedAt?: Date;
    link?: string;
  };
  createdAt: Date;
  updatedAt: Date;
  contents?: MediaContentItemForOrder[];
  unreadMessagesCount?: number;
  campaign: {
    id?: Uuid;
    featuredImage?: string;
    mentions?: string[];
    campaignLink?: string;
    name?: string;
    lang?: Lang;
    brand?: {
      name?: string;
      logoPath?: string;
    };
    socialNetworks?: {
      name?: string;
      detail?: string;
    }[];
    hashtags?: string[];
  };
  /** Field is only in response if there is conversation between the user and the brand on this campaign. If not the field is not there */
  conversationId?: string;
};
export type AdminItemForEdit = {
  id?: Uuid;
  avatarUrl?: string;
  status?: UserStatus;
  mailActivated?: boolean;
  phoneActivated?: boolean;
  gender?: 'male' | 'female';
  firstName?: string;
  lastName?: string;
  email?: string;
  lang?: Lang;
  phoneNumber?: string;
  role?: 'admin' | 'influencer';
  unreadNotifications?: number;
  consent?: boolean;
};
export type AuthItem = {
  auth?: string;
  channel_data?: string;
};
export type ItemForPermissionsLists = {
  id?: Uuid;
  name?: string;
}[];
export type ItemForBrandPermissionsLists = {
  id?: Uuid;
  name?: string;
  brandGroupId?: Uuid;
}[];
export type ItemForCampaignPermissionsLists = {
  id?: Uuid;
  name?: {
    text?: string;
    lang?: string;
  }[];
  brandId?: Uuid;
  brandGroupId?: Uuid;
}[];
export type BrandPermission = 'ALL' | 'SEE_BRAND' | 'EDIT_BRAND' | 'STEP_ONE_SET_UP_CAMPAIGN';
export type CampaignPermission =
  | 'ALL'
  | 'SEE_CAMPAIGN'
  | 'STEP_ONE_SET_UP_ALL_CAMPAIGNS_TARGETING'
  | 'EDIT_CAMPAIGN'
  | 'CHANGE_CAMPAIGN_STATUS_TO_FINISHED'
  | 'CHANGE_CAMPAIGN_STATUS_TO_RUNNING'
  | 'SEE_CAMPAIGN_REPORTING'
  | 'DELETE_CAMPAIGN'
  | 'DUPLICATE_CAMPAIGN'
  | 'SEE_ORDERS'
  | 'UPDATE_ORDER_LOGISTICS'
  | 'CHANGE_ORDER_STATUS_TO_READY_TO_SHIP'
  | 'CHANGE_ORDER_STATUS_TO_SHIPPED'
  | 'CHANGE_ORDER_STATUS_TO_RECEIVED'
  | 'CHANGE_ORDER_STATUS_TO_PUBLISHED'
  | 'CHANGE_ORDER_STATUS_TO_VALIDATED';
export type UserPermission =
  | 'EDIT_PERSONAL_INFORMATION'
  | 'SEE_THE_DASHBOARD'
  | 'SEE_GROUP_BRANDS'
  | 'CREATE_GROUP_BRANDS'
  | 'EDIT_GROUP_BRANDS'
  | 'DELETE_GROUP_BRANDS'
  | 'CREATE_BRANDS'
  | 'VALIDATE_GROUPS'
  | 'SEE_USERS'
  | 'CREATE_USERS'
  | 'EDIT_USERS'
  | 'DELETE_USERS'
  | 'VALIDATE_USERS'
  | 'SEE_CLUBS'
  | 'CREATE_CLUBS'
  | 'EDIT_CLUBS'
  | 'EDIT_CLUB_MEMBERS'
  | 'DELETE_CLUBS'
  | 'CREATE_CONVERSATION'
  | 'DELETE_MESSAGE'
  | 'EDIT_ROLES'
  | 'MANAGE_OWN_INFORMATION'
  | 'MANAGE_ALL'
  | 'GIVE_TRIBZ_ADMIN_PERMISSION_LEVEL'
  | 'VALIDATE_ADMINS'
  | 'SEE_ADMINS_TO_VALIDATE';
export type ListOfPermissionsPatchedForUser = {
  userId?: Uuid;
  groups?: Uuid[];
  brands?: {
    id?: Uuid;
    controls?: BrandPermission[];
  }[];
  campaigns?: {
    id?: Uuid;
    controls?: CampaignPermission[];
  }[];
  permissions?: {
    key?: UserPermission;
    action?: string;
  }[];
};
export type ListOfPermissionsAssignedToUser = {
  userId?: Uuid;
  groups?: {
    name?: string;
    groupId?: Uuid;
  }[];
  brands?: {
    brandId?: Uuid;
    brandGroupId?: Uuid;
    controls?: BrandPermission[];
    name?: string;
  }[];
  campaigns?: {
    campaignId?: Uuid;
    brandId?: Uuid;
    brandGroupId?: Uuid;
    controls?: CampaignPermission[];
    name?: {
      text?: string;
      lang?: Lang;
    }[];
  }[];
  permissions?: {
    key?: UserPermission;
    action?: string;
  }[];
};
export type AdminItemForPostRegistrationGeneral = {
  id?: Uuid;
  gender?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  agreePrivacyPolicy?: boolean;
  claims?: {
    brand?: string;
    group?: string;
  };
};
export type AdminItemForAdminList = {
  id?: Uuid;
  fullName?: string;
  avatarUrl?: string;
  unreadConversationsCount?: number;
  onlineStatus?: string;
  createdAt?: string;
  registeredDate?: string;
  email?: string;
  signupInfo?: {
    brand?: string;
    group?: string;
  };
  status?: UserStatus;
};
export type AdminItemForOwnership = {
  id?: Uuid;
  /** firstName + lastname */
  name?: string;
};
export type NewsItemForNewsList = {
  id?: Uuid;
  title?: string;
  category?: 'alert' | 'update';
  status?: 'published' | 'unpublished';
  description?: string;
  createdAt?: Date;
};
export type NewsItem = {
  id?: Uuid;
  status?: 'unpublished' | 'published';
};
export type GroupForBrand = {
  brandId?: Uuid;
  claimedGroup?: string;
  name?: string;
  logoPath?: string;
};
export type Leaderboard = {
  id?: Uuid;
  fullName?: string;
  points?: number;
  rank?: number;
  avatarUrl?: string;
}[];
export const {
  useLoginMutation,
  useLogoutMutation,
  usePostManageGoogleLoginMutation,
  usePostManageFacebookLoginMutation,
  usePostManageForgotPasswordMutation,
  useGetUsersQuery,
  usePostUsersMutation,
  usePatchManageUsersMutation,
  useGetManageUsersToValidateQuery,
  useGetGeneralStatisticsQuery,
  useGetUserStatisticsQuery,
  useGetManageStatisticsPlatformUsersDashboardQuery,
  useGetSocialNetworksStatisticsQuery,
  useGetCampaignsQuery,
  useCreateCampaignMutation,
  useUpdateOneCampaignMutation,
  useDeleteManageCampaignsByCampaignIdMutation,
  useGetManageCampaignsByCampaignIdQuery,
  useUpdateOneCampaignStatusMutation,
  useGetManageStatisticsSocialNetworksFilterByTargetingQuery,
  usePostManageStatisticsSocialNetworksFilterByTargetingMutation,
  useGetIncludedUsersListByFilterQuery,
  useGetManageCampaignsByCampaignIdTargetingQuery,
  usePostManageCampaignsByCampaignIdTargetingMutation,
  useUpdateFilterInformationForTargetingMutation,
  useGetManageUsersByUserIdOrdersStatisticsAndOrderIdQuery,
  useGetOrdersStatusQuery,
  useGetOrdersQuery,
  usePostManageOrdersMutation,
  useUpdateOrderMutation,
  useDeleteManageOrdersByOrderIdMutation,
  usePatchManageMediasByMediaIdMutation,
  useGetManageCampaignsByCampaignIdReportingQuery,
  useGetManageUsersByUserIdQuery,
  useGetManageUsersUserlistQuery,
  usePatchManageUsersByUserIdGeneralMutation,
  usePatchManageUsersByUserIdNotificationsMutation,
  usePatchManageUsersByUserIdSocialNetworkLinksMutation,
  usePatchManageUsersByUserIdPasswordMutation,
  usePatchManageUsersByUserIdStatusMutation,
  usePatchManageUsersByUserIdLangMutation,
  usePatchManageUsersByUserIdEmailMutation,
  useCreateNewAddressForOneUserMutation,
  useUpdateOneUserAddressMutation,
  useDeleteOneUserAddressMutation,
  useGetUserRatingsQuery,
  useCreateRatingMutation,
  useGetbrandsWithCampaignsForAddUserRatingsQuery,
  useGetManageUsersByUserIdRatingsAndRatingIdQuery,
  usePatchManageUsersByUserIdRatingsAndRatingIdMutation,
  useDeleteManageUsersByUserIdRatingsAndRatingIdMutation,
  useGetBrandsQuery,
  useCreateBrandsMutation,
  useGetManageBrandsBrandsListQuery,
  useGetOneBrandQuery,
  useUpdateBrandMutation,
  useDeleteManageBrandsByBrandIdMutation,
  usePatchManageBrandsByBrandIdNotificationsMutation,
  usePatchManageBrandsByBrandIdSocialNetworkLinksMutation,
  useUpdateOneBrandPasswordMutation,
  useGetClubsQuery,
  usePostClubsMutation,
  useGetLanguagesQuery,
  usePostLanguagesMutation,
  usePatchManageClubsByClubIdMutation,
  useGetManageClubsByClubIdQuery,
  useDeleteManageClubsByClubIdMutation,
  useGetManageClubsByClubIdStatisticsQuery,
  useGetManageClubsByClubIdUsersQuery,
  usePatchManageClubsByClubIdUsersMutation,
  useGetManageChatConversationsQuery,
  useDeleteManageChatConversationsMutation,
  usePostManageChatNewConversationMutation,
  useGetChatUsersByUserIdConversationsAndConversationIdMessagesQuery,
  usePostChatUsersByUserIdConversationsAndConversationIdMessagesMutation,
  useGetManageChatConversationsByConversationIdMessagesQuery,
  usePostManageChatConversationsByConversationIdMessagesMutation,
  usePostManageChatConversationsByConversationIdMarkAsReadMutation,
  useGetManageInterestsQuery,
  usePostManageInterestsMutation,
  useGetManageNotificationsQuery,
  usePutManageNotificationsReadMutation,
  useGetManageIndustriesQuery,
  usePostManageIndustriesMutation,
  useGetManageTagsQuery,
  useGetManageClubsClubsListQuery,
  useGetManageChatPossibleConversationsQuery,
  useGetManageUsersByUserIdOrdersAndOrderIdMediasStatisticsQuery,
  useGetManageUsersByUserIdIgMediasQuery,
  useGetManageUsersByUserIdIgStoriesQuery,
  useGetManageUsersByUserIdYoutubeQuery,
  useGetManageUsersByUserIdYoutubeShortsQuery,
  useGetManageUsersByUserIdTiktokQuery,
  usePostManageUploadMutation,
  usePostManageRefreshTokenMutation,
  usePostManageResetPasswordMutation,
  useGetManageChatConversationsByConversationIdFilesQuery,
  usePostManageChatUploadMutation,
  useGetManageUsersByUserIdGhostLoginQuery,
  useGetManageUsersByUserIdOrdersQuery,
  usePostManageUsersRegistrationMutation,
  useGetManageQuery,
  usePostManageChatAuthMutation,
  useGetManageNotificationsAuthQuery,
  usePostManageNotificationsAuthMutation,
  useGetManageNotificationsPusherBeamsAuthQuery,
  useGetManagePermissionsGroupListQuery,
  useGetManagePermissionsBrandListQuery,
  useGetManagePermissionsCampaignListQuery,
  usePatchManagePermissionsAssignMutation,
  useGetManagePermissionsQuery,
  useGetManagePermissionsByUserIdQuery,
  usePostManageOrdersExportAddressesByCampaignIdMutation,
  usePostManageSignupMutation,
  usePostManageSignupCheckEmailMutation,
  usePostManageSignupGeneralMutation,
  usePostManageSignupResendEmailCodeMutation,
  usePostManageSignupCheckPhoneNumberMutation,
  usePostManageSignupResendPhoneCodeMutation,
  useGetManageAdminsQuery,
  usePatchManageSignupPhoneNumberMutation,
  useGetManageToValidateQuery,
  usePatchManageUsersByUserIdAdminStatusMutation,
  usePostManageConsentMutation,
  usePatchManageOnboardingMutation,
  useGetManageCampaignsAdminsQuery,
  usePatchManageOrdersByOrderIdStatusMutation,
  useGetManageNewsQuery,
  usePostManageNewsMutation,
  usePatchManageNewsMutation,
  useGetManageNewsByNewsIdQuery,
  useDeleteManageNewsByNewsIdMutation,
  useGetManageBrandsToValidateQuery,
  usePatchManageBrandsByBrandIdToValidateMutation,
  useGetManageBrandsGroupListQuery,
  useGetManageOrdersByOrderIdPaidQuery,
  usePatchManageOrdersByOrderIdPaidMutation,
  useGetManageUsersLeaderboardQuery,
  useGetManageCampaignsByCampaignIdValidateQuery,
  usePatchManageCampaignsByCampaignIdValidateMutation,
  useGetManageUsersByUserIdIgReelsQuery,
} = injectedRtkApi;
